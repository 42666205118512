/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'creative-image': {
    width: 36,
    height: 36,
    viewBox: '0 0 36 36',
    data: '<path pid="0" d="M36 32V4c0-2.2-1.8-4-4-4H4C1.8 0 0 1.8 0 4v28c0 2.2 1.8 4 4 4h28c2.2 0 4-1.8 4-4zM11 21l5 6.02L23 18l9 12H4l7-9z" _fill="#666"/>'
  }
})
