/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check': {
    width: 10,
    height: 7,
    viewBox: '0 0 10 7',
    data: '<path pid="0" _fill="#666" fill-rule="nonzero" d="M9 0l.707.707-6.353 6.354L0 3.707.707 3l2.647 2.647z"/>'
  }
})
