/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'collapse-plus': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.25 11.25V3h1.5v8.25H21v1.5h-8.25V21h-1.5v-8.25H3v-1.5h8.25z" _fill="#1A1A1A"/>'
  }
})
