import { LocaleDictionary } from 'locales/locale-dictionary'

const dictionary: LocaleDictionary = {
  placeholder: {
    email: 'Email',
    password: 'Password',
    currentPassword: 'Current password',
    newPassword: 'New password',
    repeatPassword: 'Confirm password',
    phoneNumber: 'Phone',
    translationLanguage: 'Broadcast language',
    country: 'Country',
    city: 'City',
    enterCity: 'Enter city name',
    state: 'State',
    enterState: 'Enter state name',
    zip: 'Post сode',
    address: 'Address',
    enterAddress: 'Enter address',
    gender: 'Gender',
    birthYear: 'Year of birth',
    birthday: 'Date of birth',
    fullname: 'Full name',
    name: 'First name',
    enterName: 'Enter first name',
    lastname: 'Last name',
    enterLastname: 'Enter last name',
    middlename: 'Middle name',
    enterMiddlename: 'Enter middle name',
    companyName: 'Company name',
    contactPerson: 'Full name',
    commonCurrency: 'Common сurrency',
    campaign: {
      type: 'Campaign type',
      name: 'Campaign name',
    },
    inn: 'Personal Identification Number',
    iban: 'IBAN',
    bic: 'Bank BIC/SWIFT code',
    paypalEmail: 'Enter email linked to PayPal',
    selectDate: 'Select date',
    chooseCategories: 'Choose categories',
    optional: 'optional',
    passportNumber: 'Passport Number',
    panCard: 'PAN card',
    panCardIfUpload: 'If you upload PAN card we deduct 10% TDS otherwise 20% TDS',
    panCardNumber: 'PAN card Number',
    enterPassportNumber: 'Enter PN',
    enterPanCardNumber: 'Enter PAN',
    payoutMode: 'Payout Mode',
    choosePayoutMode: 'Choose Payout Mode',
    accountVpa: 'Account VPA',
    bankIFSC: 'Bank IFSC',
    beneficiaryAccountNumber: 'Beneficiary account number',
    partnerSearch: 'Search by ID or name',
    choose: 'Select',
    excludeSelected: 'Exclude selected',
    dragAFile: 'Drag file',
    dragImageHint: 'JPG or PNG, size {size} MB or less',
  },
  partnerSearchFilter: {
    add: 'Add Filter',
    status: 'Status',
    platform: 'Platform',
    advertiser: 'Advertiser',
    allAdvertisers: 'All advertisers',
  },
  button: {
    signIn: 'Log in',
    save: 'Save',
    saving: 'Saving ..',
    saved: 'Saved',
    send: {
      static: 'Send',
      pending: 'Sending ..',
      success: 'Sent',
    },
    requestHasBeenSent: 'Request has been sent',
    recoverySend: 'Send recovery link',
    recoverySent: 'Link sent',
    passwordChanged: 'Password changed',
    runAd: 'Run campaign',
    toLink: 'Link',
    remove: 'Delete',
    delete: {
      static: 'Delete',
      process: 'Deleting',
      success: 'Deleted',
      successFeminine: 'Deleted',
      successMasculine: 'Deleted',
      successPlural: 'Deleted',
    },
    create: {
      campaigns: 'Create Campaign',
      groups: 'Create Group',
      creatives: 'Create Creative',
    },
    enable: 'Enable',
    disable: 'Disable',
    sendPreview: {
      static: 'Send Preview',
      success: 'Preview Sent',
    },
    chooseFile: {
      static: 'Choose a file',
    },
    back: {
      static: 'Back',
    },
    attach: {
      static: 'Link',
    },
    join: {
      static: 'Join',
    },
    cancel: {
      static: 'Cancel',
    },
    next: {
      static: 'Next',
    },
    resetLink: 'Reset Link',
    resetQR: 'Reset QR code',
    showQR: 'Show QR code',
    hideQR: 'Hide QR code',
    showManager: 'Open Mobile Manager',
    copyLink: {
      static: 'Copy Link',
      success: 'Link Copied',
    },
    copy: {
      static: 'Copy',
      success: 'Copied',
    },
    saveChanges: {
      static: 'Save changes',
      pending: 'Saving ..',
      success: 'Changes saved',
    },
    getStarted: 'Get started',
    copyFromExisting: 'Copy from existed',
    loadMore: 'Load more',
  },
  validator: {
    required: 'Required field',
    invalidEmail: 'Incorrect email format',
    invalidPhoneNumber: 'Incorrect phone number format',
    birthYearLess: 'The year must be less than',
    birthYearMore: 'The year must be greater than',
    shortPassword: 'Password must be longer than 8 characters',
    matchPassword: 'Passwords do not match',
    positiveNum: 'Positive number only',
    alphanumeric: 'Only letters and numbers are allowed',
    inn: 'Incorrect Inn',
    bic: 'Incorrect Bik',
    swiftAllowedCharacters: 'SWIFT Code may contain only letters and numbers',
    swiftLengthDescription: 'SWIFT Code should be 8 or 11 characters',
    numbersAndSpaces: 'incorrect format',
    userInnNotFound: 'Self-employed with this TIN not found',
    bankAccount: 'Enter 20 digits without spaces',
    onlyCyrillic: 'Enter value in Cyrillic',
    urlIsInvalid: 'URL is invalid',
    embed: 'Provide a valid embed link',
    youtubeUrl: 'Invalid Youtube link',
    uniqueYoutubeUrl: 'You already added video with this id',
  },
  helpers: {
    perHour: 'Once per 1 hour',
    perMin: 'Once per {minutes} min',
    seconds: '{n} seconds | {n} second | {n} seconds',
    streamers: '{n} creators | {n} creator | {n} creators',
    inSeconds: 'in {n} seconds | in {n} second | in {n} seconds',
    someOfSome: '{0} of {1}',
    timeAgo: {
      mins: '{n} minutes ago | {n} minute ago | {n} minutes ago',
      minutes: '{n} minutes ago | {n} minute ago | {n} minutes ago',
      hours: '{n} hours ago | {n} hour ago | {n} hours ago',
      days: '{n} days ago | {n} day ago | {n} days ago',
      months: '{n} months ago | {n} month ago | {n} months ago',
      years: '{n} years ago | {n} year ago | {n} years ago',
    },
    timeLeft: {
      mins: '{n} minutes left | {n} minute left | {n} minutes left',
      minutes: '{n} minutes left | {n} minute left | {n} minutes left',
      hours: '{n} hours left | {n} hour left | {n} hours left',
      days: '{n} days left | {n} day left | {n} days left',
      months: '{n} months left | {n} month left | {n} months left',
      years: '{n} years left | {n} year left | {n} years left',
    },
  },
  pagination: {
    next: 'Next',
    prev: 'Back',
  },
  sidebar: {
    navigation: {
      streamer: {
        home: {
          title: 'Home',
        },
        dashboard: {
          title: 'Dashboard',
        },
        freemium: {
          title: 'Uplify Link',
        },
        campaigns: {
          title: 'Campaigns',
          subitems: {
            'campaigns-livestream': 'Livestream',
            'campaigns-preroll': 'Preroll',
          },
        },
        wallet: {
          title: 'Wallet',
        },
        widget: {
          title: 'Settings',
        },
        referrals: {
          title: 'Referrals',
        },
        extension: {
          title: 'Extension',
        },
        debug: {
          title: 'Debug',
        },
      },
      partner: {
        campaigns: {
          title: 'Campaigns',
          subitems: {
            'brand-awareness': 'Sponsorship',
            performance: 'Interactive',
            preroll: 'Preroll',
          },
        },
        advertisers: {
          title: 'Advertisers',
        },
        holdings: {
          title: 'Holdings',
        },
        creators: {
          title: 'Agency',
        },
        debug: {
          title: 'Debug',
        },
      },
      profile: {
        settings: 'Account settings',
        logout: 'Log out',
      },
    },
    links: {
      help: {
        label: 'Help',
        url: 'https://help.uplify.app/',
      },
      news: {
        label: 'What\'s new',
        url: '',
      },
      feedback: {
        label: 'Feedback',
        url: 'https://discord.com/channels/465868803268542475/1118478966467080283',
      },
      discord: {
        label: 'Discord',
        url: 'https://discord.gg/uplify',
      },
    },
    requestDemo: 'I need help',
    socials: {
      title: 'Follow us',
    },
  },
  other: {
    adblock: {
      bold: 'Adblock is blocking app functionality.',
      normal: 'Please add Uplify to the white list.',
    },
    advice: {
      attention: 'Attention',
      hint: 'Hint',
    },
    uploader: {
      loading: 'Loading',
      processing: 'Processing',
      declined: 'Declined',
      fileUploadError: 'File upload error',
    },
    twitchAlert: 'The site is undergoing technical work. Registration and authorization via Twitch is not available until May 19. See <a href="https://discord.gg/uplify" target="_blank">Discord</a> for details.',
    noData: 'No data',
    enabled: 'Enabled',
    disabled: 'Disabled',
    timeAgo: {
      timeAgoMins: '{n} minutes ago',
      timeAgoHours: '{n} hours ago',
      timeAgoDays: '{n} days ago',
      timeAgoMonths: '{n} months ago',
      timeAgoYears: '{n} year ago',
    },
  },
  phrases: {
    terms: 'Terms',
    privacy: 'Privacy',
    help: 'Help',
    loading: 'Loading',
    from: 'From',
    to: 'To',
  },
  closeDialog: {
    heading: 'Data not saved',
    text: 'If you close the window, the previously entered data will be deleted.',
    btnSave: 'Continue editing',
    btnContinue: 'Leave without saving',
  },
  links: {
    discord: 'https://discord.gg/uplify',
    terms: 'https://www.uplify.app/legal/terms',
    termsLink: 'https://www.uplify.app/legal/terms',
    privacy: 'https://www.uplify.app/legal/privacy-policy',
    howIsItCalculated: 'https://help.uplify.app/en/articles/5640884-how-much-can-i-earn',
    howToIncreaseCtr: 'https://help.uplify.app/en/articles/5640884-how-much-can-i-earn#h_62ec20388f',
    whenWillIReceiveMyPayouts: 'https://help.uplify.app/en/articles/5640885-when-will-i-receive-my-payouts',
    learnMoreAboutComissions: 'https://help.uplify.app/en/articles/5640886-what-payout-methods-are-supported#h_e873868c36',
    whyDoWeNeedThisData: 'https://help.uplify.app/en/articles/5640886-what-payout-methods-are-supported#h_6423c40103',
    widgetSetup: 'https://help.uplify.app/en/articles/5640838-widget-setup',
    chatbotSetup: 'https://help.uplify.app/en/articles/5640839-chatbot-setup',
    chatbotSurvey: 'https://forms.gle/BUeq3tuEZGf6zH1b6',
    inStreamAdsFormat: 'https://help.uplify.app/en/articles/5640856-in-stream-ads-format',
    whatIsTier: 'https://help.uplify.app/en/articles/5640884-how-much-can-i-earn#h_56cf3a9018',
    whatsNew: 'https://www.uplify.app/blog-category/updates',
    whyAreWeCollectingThisData: 'https://help.uplify.app/en/articles/6175695-application-permissions',
    twitchExtension: 'https://dashboard.twitch.tv/extensions/wc13k0wnf9sk4omgod6p1r3os9kulv',
    takeSurveyLink: 'https://docs.google.com/forms/d/e/1FAIpQLScC_xZYqI-nB5bsUKqToKxpDsa7z6FKU2sTrlO_AfVd-mY8aQ/viewform?usp=sf_link',
  },
  demoInfo: {
    title: 'You are in a demo account',
    text: 'Some of the features do not work and require registration',
    twitchBtn: 'Sign Up with Twitch',
    youtubeBtn: 'Sign Up with YouTube',
    trovoBtn: 'Sign Up with Trovo',
  },
  progress: {
    of: 'of',
    forecast: 'Forecast',
  },
  errors: {
    unknown: 'Unknown error',
    wrongExtension: 'Wrong file extension',
    fileSize: 'File <b>{name}</b> ({size}MB) must be less than {maxSize}MB',
  },
  campaignRow: {
    dailyActions: 'Daily actions',
    dailyActionsLimit: 'Daily actions limit',
    dailyImpressions: 'Daily views',
    dailyLimit: 'Daily limit',
    potentialIncome: 'Potential revenue',
    yourIncome: 'Your revenue',
    yourCTR: 'Your CTR',
    averageCTR: 'Average CTR',
    dateStart: 'Start date',
    dateEnd: 'End date',
    enable: 'Enable',
    disable: 'Disable',
    attended: 'Attended',
    toggling: 'Pending..',
    tags: {
      undefinedFormat: 'Unknown format',
      dailyLimitDepleted: 'Today\'s limit depleted',
      totalLimitDepleted: 'Today\'s limit depleted',
      setupExtension: 'Setup Extension',
      checkExtension: 'Check extension status',
      extension: 'Extension',
      actionBonus: 'Action Bonus',
      dailyActionsLimitDepleted: 'Action limit reached',
      performanceDailyDepleted: 'Today\'s limit depleted',
    },
    disabledUntil: 'Disabled until: {date}',
    reasons: {
      lowCTR: '🔥 Reason: low CTR',
      lowCTRLink: {
        text: 'How to improve my CTR?',
        href: 'https://help.uplify.app/en/articles/5640847-uplify-basics',
      },
      moderator: 'Reason: moderator\'s decision',
    },
    format: {
      fullscreen: 'Overlay 50%',
      pip: 'Overlay 15%',
    },
    totalImpressions: 'Available views',
  },
  campaignSidebar: {
    potentialRevenue: 'Potential revenue',
    totalRevenue: 'Your total revenue',
    revenue: 'Your revenue',
    estimatedRevenue: 'Estimated revenue',
    ctr: 'Your average CTR',
    target_ctr: 'Your CTR',
    clicks: 'Clicks',
    date: 'End date',
    dateStart: 'Start date',
    dateEnd: 'End date',
    paymentType: 'Payment type',
    pricePerViews: 'Price per 1000 views',
    pricePerAction: 'Price per 1 action',
    pricePerClick: 'Price per 1 click',
    frequency: 'Frequency',
    adFormat: 'Format',
    advertiserCategory: 'Advertiser category',
    description: 'Campaign description',
    creativePreview: 'Creative preview',
    messageInDescription: 'Message in description',
    chatMessage: 'Message in chatbot',
    campaignOn: 'Campaign on',
    campaignOff: 'Campaign off',
    productLink: 'Product link',
    copyProductLink: 'Copy personal product link',
    deliveredAtions: 'Delivered actions',
    deliveredImpressions: 'Delivered views',
    deliveredDailyActions: 'Delivered actions',
    downloadCreative: 'Download creative',
    copyDescription: 'Copy description',
    videos: 'Video Link',
    addNewVideo: 'Add another video',
  },
  intercom: {
    freemium: 'Hi! I want to try Uplify Link',
  },
  views: {
    auth: {
      streamerSignin: {
        title: 'Hi there! 👋',
        subtitle: 'Log in or sign up to your accounts by using one of your streaming platform account.',
        startWithTwitch: 'Continue with Twitch',
        startWithYoutube: 'Continue with YouTube',
        startWithTrovo: 'Continue with Trovo',
        partnerLogin: 'Partners log in',
        termsAndPolicy: 'By loging in you accept the <a href="@:links.terms" target="_blank">Terms</a> and <a href="@:links.privacy" target="_blank">Privacy</a>.',
        accountBlocked: 'Account is blocked. If this is an error, please',
        twitchAuthError: 'Twitch authorization error. Try again or',
        youtubeAuthError: 'YouTube authorization error. Try again or',
        trovoAuthError: 'Trovo authorization error. Try again or',
        discordAuthError: 'Discord authorization error. Try again or',
        accountAlreadyExists: 'You are trying to connect a platform that is linked to a different profile.',
        contactSupport: 'contact support',
        info: {
          block1: {
            title: 'Uplify helps to monetize live stream content in a modern way.',
            linkText: 'Learn more about us',
            linkHref: 'https://www.uplify.app/for-creators',
            videoHref: 'https://youtu.be/JKWaUjlIML0?si=vVppKgzscrBYX-6S',
          },
          block2: {
            title: 'We are trusted by <strong>35 000 creators</strong> and have paid out over <strong>$3 000 000</strong>.',
            linkText: 'Join our Discord community',
            linkHref: 'https://discord.gg/uplify',
          },
          block3: {
            title: '<strong>Non-disturbed formats</strong> from world-renowned brands.',
            linkText: 'Check our case studies',
            linkHref: 'https://www.uplify.app/cases',
          },
        },
      },
      streamerSettings: {
        hi: 'Hi',
        subtitle: 'We are almost there. In the meantime, let\'s get to know each other better. This is necessary for correct work of platform.',
      },
      partnerSignin: {
        title: 'Partners log in',
        forgotPassword: 'Forgot password?',
        streamerLogin: 'Creator log in',
        wannaLaunchAd: 'Want to launch a campaigns?',
        contactUs: 'Contact us to sign up',
      },
      partnerRequest: {
        partnerSignUp: 'Partners sign up',
        partnerSignIn: 'Partners log in',
        byStart: 'By clicking "Send" you accept the',
        terms: 'Terms',
        and: 'and',
        policy: 'Privacy',
        successMsg: 'We have sent instructions describing further actions to your email. Our team will contact you shortly.',
      },
      resetPassword: {
        title: 'Password reset',
        description: 'Enter the email associated with your account and we\'ll send you password reset link.',
        partnerSignIn: 'Partners log in',
      },
      newPassword: {
        title: 'Create a new password',
        descriptionFirstRow: 'Password cannot be less than 8 characters.',
        descriptionSecondRow: 'Do not use passwords from other websites.',
        successText: 'Password changed',
        openNow: 'Open now',
        pageOpenIn: 'Log in page will open',
      },
      scopeTwitch: {
        heading: 'Before we start',
        headline: 'For the Uplify service to&nbsp;operate properly we&nbsp;need your permission to&nbsp;access your channel stats and send chat messages.',
        info: 'We&nbsp;respect your privacy and treat your data with the utmost sensitivity.',
        link: 'Why are we collecting this data?',
        desciptions: [
          {
            title: 'Send live Stream Chat and Rooms messages',
            text: 'This one allows&nbsp;us to&nbsp;send chat messages with a&nbsp;link to&nbsp;the sponsor&rsquo;s website.',
          },
          {
            title: 'Obtain your email address',
            text: 'Your email is&nbsp;requested by&nbsp;Uplify to&nbsp;confirm your registration and send important notifications.',
          },
          {
            title: 'View your channel&rsquo;s moderation data including Moderators, Bans, Timeouts and Automod settings',
            text: 'Necessary for the chatbot to&nbsp;work properly and avoid conflicts with other chatbots in&nbsp;your channel.',
          },
          {
            title: 'View your channel&rsquo;s broadcast configuration',
            text: 'Read-only access. Allows Uplify to&nbsp;optimize the delivery of&nbsp;sponsored campaigns to&nbsp;you.',
          },
          {
            title: 'View live Stream Chat and Rooms messages',
            text: 'Allows&nbsp;us to&nbsp;read channel chat messages and conduct interactive polls.',
          },
        ],
        redirectBtn: 'Go to Twitch',
      },
      scopeYoutube: {
        heading: 'Before we start',
        headline: 'For the Uplify service to&nbsp;operate properly we&nbsp;need your permission to&nbsp;access your channel stats and send chat messages.',
        info: 'We&nbsp;respect your privacy and treat your data with the utmost sensitivity.',
        link: 'Why are we collecting this data?',
        desciptions: [
          {
            title: 'Manage your YouTube account',
            text: 'Allows&nbsp;us to&nbsp;get the number of&nbsp;active viewers on&nbsp;the live stream, to&nbsp;read and send chat messages with a&nbsp;link to&nbsp;the sponsor&rsquo;s website.',
          },
        ],
        redirectBtn: 'Go to YouTube',
      },
      scopeTrovo: {
        heading: 'Before we start',
        headline: 'For the Uplify service to&nbsp;operate properly we&nbsp;need your permission to&nbsp;access your channel stats and send chat messages.',
        info: 'We&nbsp;respect your privacy and treat your data with the utmost sensitivity.',
        link: 'Why are we collecting this data?',
        desciptions: [
          {
            title: 'View your email address and other user profiles',
            text: 'Your email is&nbsp;requested by&nbsp;Uplify to&nbsp;confirm your registration and send important notifications.',
          },
          {
            title: 'View your channel details',
            text: 'Read-only access. Allows Uplify to&nbsp;optimize the delivery of&nbsp;sponsored campaigns to&nbsp;you.',
          },
          {
            title: 'Send chats to&nbsp;my&nbsp;channel',
            text: 'This one allows&nbsp;us to&nbsp;send chat messages with a&nbsp;link to&nbsp;the sponsor&rsquo;s website.',
          },
        ],
        redirectBtn: 'Go to Trovo',
      },
    },
    streamer: {
      home: {
        header: {
          greeting: 'Hi',
          introduction: 'I\'m Constantine from Uplify. I\'ll help you get to know your viewers better and start earning from live broadcasts.',
          letsDoIt: 'Let\'s do it!',
        },
        about: {
          label: 'WHO WE ARE',
          title: 'Uplify is a platform to work with your sponsorships directly',
          description: 'You have reached the open beta testing for English-speaking creators. Connect our widget, and soon you can start monetizing your streams.',
          howItWorks: 'See how it works',
          setup: 'Set up Uplify',
          statistic: {
            campaigns: {
              title: 'We complete more than',
              amount: '50 ad<br>campaigns',
            },
            payouts: {
              title: 'We payout more than',
              amount: '$400,000<br>for creators',
            },
          },
          howUplifyWorks: 'How Uplify works',
        },
        next: {
          label: 'WHAT\'S NEXT',
          description: 'Once you complete, we\'ll start analyzing your audience and find the right advertisers in the next 2 weeks. Make yourself at home!',
          steps: {
            first: 'Visit any page and <a href="https://help.uplify.app" target="_blank">read how our service works →</a>',
            second: 'Join to our creators community at <a href="https://discord.gg/uplify" target="_blank">Discord →</a>',
            third: 'Write to me with any questions via chat',
          },
        },
      },
      dashboard: {
        dashboard: 'Dashboard',
        greeting: 'Welcome back, {name} 👋',
        setup: {
          finishYourSetup: 'Finish your setup',
          stepsLeft: 'step left | steps left',
          aboutXMin: 'About {min} min',
          steps: {
            fillYourProfile: 'Complete your profile',
            configureWidget: 'Configure widget',
            connectChatbot: 'Connect a chatbot',
            getFirstSponsorship: 'Get your first sponsorship',
            freemium: 'Earn more with your community',
          },
          guides: {
            fillYourProfile: 'https://www.youtube-nocookie.com/embed/ykLXIguDxeA?rel=0&controls=0&showinfo=0&autoplay=1&modestbranding=1&version=3&hl=en_US&autohide=1',
            configureWidget: 'https://www.youtube-nocookie.com/embed/7yTaaRcvnNQ?rel=0&controls=0&showinfo=0&autoplay=1&modestbranding=1&version=3&hl=en_US&autohide=1',
            connectChatbot: 'https://www.youtube-nocookie.com/embed/TKvlptfQ0ZE?rel=0&controls=0&showinfo=0&autoplay=1&modestbranding=1&version=3&hl=en_US&autohide=1',
            getFirstSponsorship: 'https://help.uplify.app/en/articles/5640840-running-in-stream-campaigns',
            freemium: 'https://fanatical-fly-511.notion.site/Streamer-support-page-6c5d0c3b3e62448e94aa2ce1223e4986',
          },
          stepByStepGuide: 'Step-by-step guide',
          closeChecklist: 'Close',
        },
        values: {
          estimatedEarnings: 'Estimated earnings for&nbsp;{month}',
          turnOnUplifyPromo: 'Check your wallet',
          earnedByReferalls: 'Earned by referalls',
          getReferalLink: 'Get referal link',
          newCampaignsComingSoon: 'New campaigns coming soon',
          activeCampaignsToday: 'active campaign today | active campaigns today',
          goToCampaigns: 'Go to campaigns',
          earnedByFreemium: 'Earned with Uplify Link',
          freemiumLink: 'Set a goal',
        },
        resources: {
          discordText: 'Join to our Discord Server and share your experience with community',
          blogText: 'Get latest updates and streamers tips in our Blog',
          helpCenterText: 'Learn all about Uplify in our Help Center',
        },
        advice: {
          heading: 'why it’s matter?',
          title: 'Get from $100 per month',
          description: 'In order to start monetizing your channel and get access to the best sponsors you need to complete ',
          steps: '{n} steps | {n} step | {n} steps',
          descriptionEnd: 'Let\'s do it!',
        },
        levels: {
          heading: 'Your growth',
          goals: 'Level {level} Goals',
          perks: 'Level {level} Perks',
          lvl: 'LVL',
          days: 'days',
          discord: {
            active: 'Joined',
            notActive: 'Join',
          },
          twitch: {
            active: 'Active',
            notActive: 'Setup',
          },
          advice: {
            label: 'about levels',
            title: 'Level Up to unlock more perks and features',
            description: 'The Uplify Loyalty Program is a series of incremental goals that you can strive to achieve while streaming on any of the supported Platforms. As you continue to grow and gain new levels, there will be different features and perks that become available to you.',
            link: {
              text: 'About levels',
              url: 'https://help.uplify.app/en/articles/6563236-streamer-loyalty-program',
            },
          },
        },
      },
      campaigns: {
        header: {
          title: 'Campaigns',
        },
        analytics: {
          returnBtn: 'All campaigns',
          reportBtn: {
            title: 'Campaign report',
            shortTitle: 'Report',
            loading: 'Loading',
            noData: 'No data',
          },
          fields: {
            clicks: 'Clicks',
            ctr: 'CTR',
            impressions: 'Views',
            botClicks: 'Fraud clicks',
            date: 'Date',
          },
        },
        tabs: {
          current: 'Actual',
          completed: 'Completed',
        },
        platforms: {
          title: 'Your current active platform for monetization',
          link: {
            text: 'Change platform',
          },
        },
        freemium: {
          title: 'More ways to earn',
          msg: {
            title: 'SUPERCHARGE YOUR INCOME WITH UPLIFY LINK',
            text: 'Discover the key to&nbsp;recurring revenue, engagement, and meaningful viewer relationships. Start earning up&nbsp;to&nbsp;70% more by&nbsp;inviting your fans to&nbsp;support you now.',
            link: {
              text: 'Setup Uplify Link',
            },
            getAccess: 'Get access',
          },
        },
        active: {
          title: 'Active campaigns',
          description: 'Your campaigns with detailed statistics in real-time.',
        },
        inactive: {
          title: 'Inactive campaigns',
          description: 'According to the ignored categories or disabled by you or moderator.',
          link: 'Write to support if this is an error',
          linkHref: 'https://discord.gg/uplify',
        },
        future: {
          title: 'Upcoming campaigns',
          description: 'Campaigns that are going to be avaliable in the near future.',
        },
        notAvailable: {
          title: 'Unavailable campaigns',
          description: 'Campaigns that are not available to you, or that are disabled by the moderator. If this is an error, contact support.',
        },
        advice: {
          title: 'It is important to know',
          dailyLimit: {
            title: 'Daily limit',
            description: 'The maximum number of views available to user on a daily basis.',
          },
          dailyActionLimit: {
            title: 'Daily action limit',
            description: 'The maximum number of actions available to all users on a daily basis. Please note that actions exceeding the daily limits will not be payed.',
          },
          potentialIncome: {
            title: 'Potential revenue',
            description: 'The potential amount that can be earned from a campaign.',
            link: 'How is it calculated?',
          },
          ctr: {
            title: 'CTR',
            description: 'is a measure of the clickability of an ad. The higher the number, the better.',
            link: 'How to increase CTR?',
          },
        },
        none: [
          'Campaigns are coming soon',
        ],
      },
      widget: {
        header: {
          title: 'Settings',
          widget: 'Widget',
          chatbot: 'Chatbot',
          extension: 'Extension',
          stream: 'Livestream',
          disabled: 'OFF',
          enabled: 'ON',
        },
        widgetPlatform: {
          title: 'Monetization platform',
          description: 'Choose the platform where you will work withg sponsors, collect statistics and monetize. The choice of a platform will affect the available campaigns and widget modules.',
        },
        widgetLink: {
          title: 'Widget link',
          description: 'Widget for OBS or other broadcast software.',
          optionDrag: {
            label: 'Option 1: Drag and Drop into OBS',
            helpWithSetup: 'Step-by-step guide',
            warn: 'Drag and Drop into OBS will not work if OBS is running as Administrator.',
            btn: 'Drag and Drop into OBS',
          },
          optionLink: {
            label: 'Option 2: Setup widget from scratch',
            helpWithSetup: 'Step-by-step guide',
            warn: 'Make sure the widget is set to the entire visible area in your broadcast software and in the top layer, and the correct settings selected. Check the guide.',
          },
          preview: 'Display test animation in broadcast software.',
        },
        widgetDelay: {
          title: 'Broadcast Delay',
          new: 'new',
          description: 'Set the broadcast delay time for the correct operation of the service and chatbot notifications.',
          howItWorks: 'How it works',
          fieldLabel: 'Delay time (0 to 1800 s)',
        },
        chatbot: {
          title: 'Chatbot setup',
          description: 'The chatbot is used to send messages and surveys.',
          surveyDescription: 'Can\'t setup Nightbot or you’re working with another bot?',
          wantAnotherChatbot: 'I want another chatbot',
          shareChatbotSetup: 'Please share with us your experience about chatbot setup',
          goThroughTheSurvey: 'Go through the survey',
          helpWithSetup: 'Step-by-step guide',
          connectNightbot: 'Connect Nightbot',
          disconnectNightbot: 'Disable Nightbot',
          checkChatbot: 'Check Nightbot',
          sendMessage: 'Message preview',
          messageSent: 'Message sent',
          error: 'Before connecting the bot, please <a href="https://nightbot.tv/dashboard" target="_blank">visit nightbot website</a> and end the game session.',
        },
        advertSettings: {
          title: 'Campaigns manager',
          description: 'Select the frequency and placement mode.',
          moreAboutFormats: 'Learn more about formats',
          bannerTimeout: 'Frequency',
          adPlaybackMode: 'Placement mode',
          playbackModeManual: 'Manual',
          playbackModeAuto: 'Auto',
          manualPlaybackTitle: 'Campaign launch',
          pictureInPicturePosition: 'Picture-in-Picture location',
          leaderboardPosition: 'Wide banner location',
          adsBlocksCountTitle: 'Number of placement',
          adsBlocksCountDuration: 'Duration: {seconds} sec',
          advice: {
            title: 'Manual campaigns launch',
            firstString: 'You can run campaigns manually when the widget, extension and chatbot are active.',
            secondString: 'As soon as the campaigns is available, the button is unlocked.',
          },
        },
        panel: {
          title: 'Remote Campaigns manager',
          mobileVersion: {
            title: 'Mobile Campaigns manager',
            description: 'Scan the QR code to open the Campaigns Manager on your phone. Do not show this code to anyone.',
          },
          obsVersion: {
            title: 'Campaigns Manager for OBS',
            description: 'Campaigns Manager can be added to OBS. Copy the link and paste it under View → Docks → Custom Browser Docks. Do not show this link to anyone.',
          },
        },
        ignoreCategories: {
          title: 'Categories to ignore',
          description: 'Campaigns from the selected categories will be automatically disabled for display in the Campaigns section.',
        },
        attention: {
          title: 'Widget and chatbot setup',
          widgetDisabled: {
            title: 'Widget disabled',
            reasons: {
              title: 'The following reasons are possible:',
              broadcast: 'Broadcast program is disabled',
              notSetUp: 'The widget is not setup in the broadcast program.',
            },
          },
          chatbotDisabled: {
            title: 'Chatbot disabled',
            reasons: {
              toBeConnected: 'Chatbot has to be connected for the channel',
              addChatbotAsModerator: 'Need to add Nightbot as a moderator',
            },
          },
        },
        twitchExtension: {
          title: 'Twitch Extension',
          description: 'Setup an extension for Twitch to get special sponsorship campaigns in Extension format.',
          status: 'Extension status',
          btns: {
            setup: 'Setup extension',
            check: 'Check the extension',
          },
        },
      },
      wallet: {
        header: {
          title: 'Wallet',
        },
        tabs: {
          overview: 'Overview',
          history: 'Payout history',
        },
        balance: {
          heading: 'Your revenue',
          title: 'Balance',
          currentBalance: {
            label: 'Balance',
            popover: 'Verified income amount',
          },
          minimumPayout: 'Minimum payout amount',
          payoutAmount: 'Amount to payout',
          nearestDatePayout: 'Nearest payout date',
          howPayoutsWork: 'How do payouts work?',
          referralInfo: 'Congratulations! You have earned a referral bonus of {sum}. <br/><br/>Please be aware that in order to retain this bonus, the terms of the program require you to reach a certain number of views by {date}. <br/><br/>Views: {imps} / {impsTotal}.',
          estimatedEarnings: {
            label: 'Estimated earnings for&nbsp;{month}',
            popover: 'Income will be&nbsp;adjusted after checking for possible fraud',
          },
          auditedEarnings: {
            label: 'Amount under review',
            popover: 'Amount to be verified',
          },
          nextPayout: 'Payout amount on {date}',
        },
        paymentServices: {
          all: {
            title: 'Payout services',
            loading: 'loading',
            active: 'Active for payouts',
            activate: 'Default method',
            learnMore: 'Learn more about commissions',
            paypal: 'PayPal',
            wireTransfer: 'Wire transfer',
            paypalData: 'Billings data',
            billingsData: 'Billings data',
            whyDoWeNeedThisData: 'Why do we need this data?',
            setUp: 'Set up payout',
          },
          razorPay: {
            title: 'Payout onboarding',
            loading: 'loading',
            learnMore: 'Learn more about commissions',
            setUp: 'Set up payout',
            statuses: {
              yourStatus: 'Your status',
              paymentMethod: 'Payment method',
              payable: 'Payable',
              notPayable: 'Not payable',
              notAdd: 'Not add',
            },
            form: {
              title: 'Billings data',
              whyDoWeNeedThisData: 'Why do we need this data?',
            },
          },
          tochkaBank: {
            title: 'Payout services',
            loading: 'loading',
            learnMore: 'Learn more about commissions',
            setUp: 'Set up payout',
            employment: {
              type: 'Employment type',
              physical: 'Individual',
              physicalShort: 'Individual',
              selfEmployed: 'Self-employed',
              taxHintSelf: 'The self-employed are required to pay taxes on income in the amount of 6% of the amount of remuneration. \n \n The self-employed person pays the tax himself on a receipt from the tax office.',
              taxHintPhysical: 'Individuals are required to pay taxes on income in the amount of 13% of the amount of remuneration. We will transfer this amount to the tax ourselves.',
              selfRegisterHint: 'To get started, connect the service partner “Я – сам” and be sure to give Uplify permission to interact with the service. \n \n Integration with “Я – сам” allows you to automatically make payments, generate checks, calculate and pay tax on the income.',
              formHint: '',
              status: 'Status',
              registered: 'Registered',
              notRegistered: 'Not registered',
              register: 'Connect',
            },
            statuses: {
              yourStatus: 'Your status',
              paymentMethod: 'Payment method',
              payable: 'Payable',
              notPayable: 'Not payable',
              notAdd: 'Not add',
            },
            form: {
              title: 'Account linking',
              whyDoWeNeedThisData: 'Why do we need this data?',
            },
            paymentStatus: {
              lastTransaction: 'Last payment',
              status: {
                inProgress: 'In progress',
                error: 'Error',
                success: 'Success',
              },
              fields: {
                amount: 'Amount',
                date: 'Date',
                status: 'Status',
                methods: 'Payment methods',
              },
            },
          },
        },
        analytics: {
          heading: 'Income analytics',
          categories: {
            awareness: 'Sponsorship formats',
            performance: 'Interactive formats',
            actions: 'Action bonus',
            freemium: 'Uplify Link',
            referrals: 'Referrals',
            youtube_text: 'Youtube Text',
          },
          source: {
            title: 'Income source',
          },
        },
        payoutHistory: {
          title: 'Payout history',
          columns: {
            date: 'Date',
            amount: 'Amount',
            service: 'Payment method',
            invoice: 'Invoice',
          },
          loading: 'loading',
          noData: 'No payouts have been made yet.',
        },
        tipaltiAdvice: {
          title: 'Earn first {amount} to unlock set up withdrawals',
          description: 'The payout setting is only available to creators who have earned 80% of the minimum payout. ',
        },
        tochkaAdvice: {
          title: 'Connect one of the payout services',
          description: 'You don\'t have any connected payout services. Connect a prefer way to receive payouts.',
        },
      },
      profile: {
        header: {
          title: 'Account settings',
        },
        tier: {
          whatIsTier: 'What is a tier?',
        },
        agency: {
          title: 'Agency',
        },
        form: {
          howToChangeLanguage: 'To change the broadcast language and country, send us a message in chat or Discord.',
        },
        advice: {
          title: 'Email verification required',
          info: 'We only inform you about the most important news and updates.',
          noSpam: 'No spam.',
          clickLink: 'Please click on the link in the confirmation email we sent you.',
          link: 'Send again',
          linkClicked: 'Mail sent',
        },
        platforms: {
          title: 'Linked platforms',
          writeUsToDetach: 'Write to us to unlink',
        },
        discord: {
          title: 'Join our Discord server to keep up to date, get support and level up.',
          status: {
            caption: 'Your status',
            connected: 'Conneted',
            notConnected: 'Not Connected',
          },
        },
        deactivation: {
          title: 'Account deactivation',
          textBlock1: 'If&nbsp;you want a&nbsp;break from Uplify, you can temporarily deactivate your account instead of&nbsp;deleting&nbsp;it. Your profile won&rsquo;t appear on&nbsp;Uplify while you&rsquo;re away.',
          textBlock2: 'If&nbsp;you continue, your profile and account details will be&nbsp;deleted on&nbsp;<strong>{date}</strong>. You won&rsquo;t be&nbsp;visible on&nbsp;Uplify between now and then. If&nbsp;you change your mind, you can log back in&nbsp;before the deletion date and choose to&nbsp;keep your account.',
          deactivateBtn: 'Deactivate',
          drawer: {
            title: 'Deactivate account',
            subtitle: 'Are you sure you want to&nbsp;deactivate your account?',
            textBlock1: 'When an&nbsp;Uplify account is&nbsp;deactivated, the following actions happen to&nbsp;the account&rsquo;s data:',
            textBlock2: [
              'The profile, public page, widget are hidden from the platform, and will no&nbsp;longer be&nbsp;visible to&nbsp;other users.',
              'Any type of&nbsp;campaigns will not be&nbsp;available.',
              'The balance will be&nbsp;available until the account is&nbsp;deleted and is&nbsp;only payable when the minimum threshold is&nbsp;reached.',
              'Uplify will keep the account&rsquo;s data while it&nbsp;is&nbsp;deactivated. The account owner can reactivate the account at&nbsp;any time during this period.',
              'If&nbsp;the account is&nbsp;not reactivated within a&nbsp;certain period of&nbsp;time, Uplify may delete the account&rsquo;s data permanently. Uplify states that this process can take up&nbsp;to&nbsp;40&nbsp;days.',
              'Uplify also states that they may retain certain information from deactivated accounts if&nbsp;it&nbsp;is&nbsp;necessary to&nbsp;comply with legal obligations, resolve disputes, prevent fraud, or&nbsp;enforce their policies.',
            ],
            textBlock3: 'It&rsquo;s important to&nbsp;note that deactivating an&nbsp;account is&nbsp;not the same as&nbsp;deleting an&nbsp;account, and it&rsquo;s a&nbsp;reversible process, where the account and its data can be&nbsp;restored.',
            confirmBtn: 'Confirm',
            cancelBtn: 'Cancel',
          },
        },
      },
      referrals: {
        header: {
          title: 'Refer-a-friend program',
        },
        unavailable: 'We\'re sorry, the referral program is not available for you or your region. <br>Write us on <a href="https://discord.gg/uplify" target="_blank">Discord</a> or website chat if you have any questions.',
        invite: {
          title: 'Invite friends',
          description: 'Get {referrer} for each friend you invite, and your friend will receive {referral} after they complete sign up and showing first campaigns.',
          invited: 'Invite',
          reward: 'Amount to payout',
          link: 'Invite link',
          advice: {
            title: 'How do I get paid?',
            description: 'You will receive a payout when a friend signs up for Uplify, confirms the email, and show campaigns for 5,000 views.',
            link: {
              label: 'Terms of the Refer-a-friend program',
              url: '/',
            },
          },
        },
        promotion: {
          title: 'Promotion on livestream',
          switchLabel: 'Promote Uplify on your livestream.',
          advice: {
            title: 'How it works',
            description: 'You can promote our service on your livestream. You will get {referrer} and your referrals will get {referral} after complete all terms. <br/><br/>Every two hours, Uplify will send a personalized banner during the livestream, urging to join our service',
          },
          link: {
            label: 'Read more about promotion',
            url: '/',
          },
          demo: {
            title: 'Click to see preview of a banner in broadcast software.',
          },
        },
        panels: {
          title: 'Twitch panels',
          description: 'Place a banner under your player to invite more users.',
        },
        history: {
          title: 'Invite history',
          none: 'You have not invited anyone yet :(',
          columns: {
            creator: 'Referral',
            date: 'Join date',
            status: 'Status',
            impressions: 'Views',
          },
          paid: 'Paid',
          waiting: 'Waiting',
        },
        updated: {
          invite: {
            title: 'Invite friends',
            description: 'Get {referrer} for each friend you invite, and your friend will receive {referral} after they complete sign up and showing first campaigns.',
            invited: 'Invite',
            reward: 'Amount to payout',
            link: 'Invite link',
            advice: {
              title: 'How do I get paid?',
              description: 'You can promote Uplify on your livestream. You will earn 5% of the income of all the cretors you refer. <br/><br/>Every two hours, Uplify will send a personalized banner during the livestream, urging to join our service.',
              link: {
                label: 'Terms of the Refer-a-friend program',
                url: '/',
              },
            },
          },
          promotion: {
            title: 'Promotion on livestream',
            switchLabel: 'Promote Uplify on your livestream.',
            advice: {
              title: 'How it works',
              description: 'You can promote our service on your livestream. You will get {referrer} and your referrals will get {referral} after complete all terms.',
            },
            link: {
              label: 'Read more about promotion',
              url: '/',
            },
            demo: {
              title: 'Click to see preview of a banner in broadcast software.',
            },
          },
          history: {
            advice: {
              title: 'It is important to know',
              description: '<strong>Invited</strong>: The user has registered but has not completed onboarding.<br/><br/><strong>Onboarded</strong>: The user has completed onboarding and is&nbsp;ready to&nbsp;show sponsored campaigns.',
            },
            invited: 'Invited',
            hasImpressions: 'Onboarded',
          },
        },
      },
      referralCheck: {
        title: 'Your referral link works great',
        text: 'Your friends can sign up and get a bonus. <br><a href="https://discord.gg/uplify" target="_blank">Contact with us</a> if you have any questions.',
        btnText: 'Back to Home',
      },
      extension: {
        header: {
          title: 'Twitch Extension',
        },
        about: {
          title: 'About',
          description: 'Get all the links to your content in one place. With blocks, you can link to your stores, specials and social networks. Create your own unique block design, add links, and you\'re done.',
          status: 'Extension status',
          statusTrue: 'All work',
          statusFlase: 'Not working',
        },
        settings: {
          title: 'Settings',
          description: 'Create your own unique block design and add them to the panel.',
          form: {
            block: {
              title: 'Block',
              type: {
                label: 'Block type',
                placeholder: 'Select block type',
              },
              link: {
                label: 'Go to link',
                placeholder: 'Paste link',
              },
              deleteBlock: 'Delete block',
            },
            addBlock: 'Add one more block',
          },
        },
        preview: {
          label: 'Panel preview',
          title: 'My links tree',
          action: 'Tap to go forward',
        },
      },
      freemium: {
        title: 'Uplify Link',
        tabs: {
          setup: 'Widget setup',
          profile: 'Profile',
          alerts: 'Stream alerts',
          supporters: 'Analytics',
          posts: 'Posts',
        },
        posts: {
          addNewBtn: 'Add new post',
          post: {
            createdAt: 'Posted on',
            edit: 'Edit',
            remove: 'Remove',
          },
          sidebar: {
            title: {
              add: 'Add new post',
              edit: 'Editing post',
            },
            markdown: {
              label: 'Markdown text',
              hint: 'For example:<br><br>I just love **<b>bold text</b>**.<br>Also *<i>italic text</i>* is pretty cool.<br><br>My favorite link is [Uplify.link](https://uplify.link).',
            },
            embed: {
              label: 'Embed Link',
              hint: 'Some text',
            },
            btns: {
              post: 'Post',
              submit: 'Save',
            },
          },
        },
        setup: {
          link: {
            title: 'Your Uplify Link page',
            description: 'Add this link to your social media bios and share with your community. Earn more by sharing your link regularly.',
            errors: {
              unique: 'Creator with this name already exists',
              general: 'Invalid name',
            },
          },
          goal: {
            title: 'Set a goal ',
            description: 'Goals tend to attract more supporters. Add goal and let them be a part of your creative journey.',
            addGoal: 'Add goal',
            reachedOf: '100 of 2 000 reached!',
          },
          goalForm: {
            title: 'Create a new goal',
            editTitle: 'Edit goal',
            fields: {
              title: {
                caption: 'Goal title',
                placeholder: 'e.g. New webcam',
              },
              description: {
                caption: 'Goal description',
                placeholder: 'When I reach 200 points, I’ll buy a new webcam to move my livestreams to a new level.',
                hint: 'Give a short descrtiption of the reasons why you need to reach your goal.',
              },
              amount: {
                caption: 'Goal amount',
                hint: 'Set an amount without the currency sign e.g. 100. Goal progress will be shown as a percentage.',
              },
              progress: {
                caption: 'Progress so far',
                hint: 'Enter the amount that you have already raised towards this goal. Enter 0 if you’re starting from scratch.',
              },
              publicAmount: {
                caption: 'Show target amount publicly',
                hint: 'Show the total target amount on your personal page',
              },
            },
            statusMessage: {
              success: 'Goal was updated',
              fail: 'Error updating goal',
            },
          },
          poll: {
            title: 'Set a new poll',
            description: 'Polls help better engage your audience, which will simultaneously increase your engagement rate and earnings. Viewers should watch the ads before voting, and you\'ll get revenue from the ads you show.',
            addPoll: 'Add poll',
            duration: 'Duration',
            votes: 'Votes - {num} left',
          },
          pollForm: {
            title: 'Create a new poll',
            editTitle: 'Edit poll',
            responsesTitle: 'Answers',
            responsesTitleMin: '(minimum 2)',
            fields: {
              question: {
                caption: 'Question',
                placeholder: 'e.g. What game should I play next?',
              },
              answer: {
                caption: 'Answer',
              },
              condition: {
                caption: 'Poll mode',
                hint: 'Termination condition: by time or number of votes',
                duration: 'Minutes',
                maxVotes: 'Votes',
              },
              duration: {
                caption: 'Duration in minutes',
                hint: 'The validity time of your poll.',
              },
              maxVotes: {
                caption: 'Votes goal',
                hint: 'The poll will end when reach this number of votes',
              },
            },
            statusMessage: {
              success: 'Poll was updated',
              fail: 'Error updating poll',
            },
          },
        },
        profile: {
          telegram: {
            title: 'Auto reposter',
            description: 'Connect your social network and posts will automatically appear on&nbsp;your Uplify Link page.',
            input: {
              title: 'Telegram channel',
              description: 'Add a&nbsp;link to&nbsp;your channel with @ and assign our bot @UplifyBot as&nbsp;an&nbsp;admin.',
              label: 'Link',
              placeholder: '@my_channel',
            },
            errors: {
              channelExists: 'There is no channel with the this name',
              invalidName: 'The wrong channel name',
            },
          },
          about: {
            title: 'Your bio',
            description: 'Add details for your profile',
            placeholder: 'Just a few words',
            preview: 'Preview:',
            advice: {
              label: 'HINT',
              title: 'You can use Markdown',
              description: 'For example:<br><br>I just love **<b>bold text</b>**.<br>Also *<i>italic text</i>* is pretty cool.<br><br>My favorite link is [Uplify.link](https://uplify.link).',
            },
            ai: {
              label: 'AI generated text for your profile',
              delete: 'Delete',
              edit: 'Edit',
              like: 'Like',
              suggestionFull: 'Here\'s what our AI thinks about {name}',
              suggestionEmptyMale: '{name} didn\'t write anything about himself, but here\'s what our AI can say',
              suggestionEmptyFemale: '{name} didn\'t write anything about herself, but here\'s what our AI can say',
            },
          },
          gear: {
            title: 'Gears and setup',
            description: 'Tell your audience what kind of&nbsp;streaming equipment you use',
            form: {
              addBlock: 'Add block',
              deleteBlock: 'Delete block',
              block: {
                title: 'Equipment',
                category: {
                  label: 'Category',
                  placeholder: 'Specify a category',
                },
                description: {
                  label: 'Description',
                  placeholder: 'Describe the equipment',
                },
                link: {
                  label: 'Link',
                  placeholder: 'Provide a link',
                },
                sku: {
                  label: 'SKU',
                  placeholder: '',
                },
              },
            },
            advice: {
              label: 'Hint',
              title: 'Yandex.Market widget',
              text: 'Check the availability of&nbsp;products on&nbsp;Yandex.Market. If&nbsp;at&nbsp;least one is&nbsp;missing, a&nbsp;widget with similar offers will be&nbsp;shown instead of&nbsp;your products.',
            },
          },
          social: {
            title: 'Social Links',
            description: 'Share social network links to your audience',
            form: {
              addBlock: 'Add block',
              deleteBlock: 'Delete block',
              block: {
                title: 'Link',
                category: {
                  label: 'Social network',
                  placeholder: 'Select social network',
                },
                description: {
                  label: 'URL',
                  placeholder: 'Paste link',
                },
              },
            },
          },
          theme: {
            title: 'Color theme',
            description: 'Choose a color theme for your Uplify Link page',
            select: {
              label: 'Color theme',
              options: {
                light: 'Light',
                dark: 'Dark',
              },
            },
          },
          banner: {
            title: 'Profile banner',
            description: 'Customize your profile banner on personal page',
            label: 'Upload a PNG, JPG under 5MB. Image size should be at least 1376x300 px.',
          },
        },
        alerts: {
          supportAlert: 'Support alert',
          goalAlert: 'Goal alert',
          pollAlert: 'Poll alert',
          chatAlert: 'Chat alert',
          preview: 'Preview',
          alertPreview: {
            title: 'Alert preview',
            description: 'Setup your widget in your OBS software (OBS Studio, Twitch Studio, or Xsplit) and test alerts before livestream.',
            sendTest: 'Send preview',
          },
          chatBotReminder: 'Requires setting up a chat bot',
          fields: {
            messageDuration: {
              caption: 'Alert display time',
              hint: 'How long an alert will be displayed on the live stream.',
            },
            supportAlertPosition: {
              caption: 'Support alert position',
              hint: 'Specifies where on the screen alert will be displayed.',
            },
            goalAlertPosition: {
              caption: 'Goal alert position',
              hint: 'Specifies where on the screen alert will be displayed.',
            },
            pollAlertPosition: {
              caption: 'Poll alert position',
              hint: 'Specifies where on the screen alert will be displayed.',
            },
            chatAlert: {
              caption: 'Enable chat alerts',
              hint: 'Specifies whether or not to show interactive notifications during a live broadcast.',
            },
          },
          statusMessage: {
            success: 'As configurações foram atualizadas',
            fail: 'Erro ao atualizar as configurações',
          },
        },
        supporters: {
          stats: {
            supporters: 'Monthly supporters',
            revenueDay: 'Today estimated revenue',
            revenueMonth: 'Monthly estimated revenue',
            cpm: 'Avg. price per 1.000 views (CPM)',
            impressions: 'Delivered impressions',
            points: 'Monthly points',
          },
          history: {
            supporters: 'Supporters',
            supportedYou: 'supported you!',
            noSupportersTitle: 'You don’t have any supporters yet',
            noSupportersMessage: 'Encourage your viewers visit your page to get first supports',
            impressions: '{imps} ad views',
            targets: {
              today: 'Today',
              yesterday: 'Yesterday',
              week: 'This week',
              month: 'This month',
              allTime: 'All time',
            },
            columns: {
              id: '#',
              name: 'Name',
              contribution: 'Points',
            },
            cacheTimeLeft: 'This rating will be updated in {min} min.',
          },
        },
        widgetPosition: {
          leftTop: 'Left top corner',
          rightTop: 'Right top corner',
          leftBottom: 'Left bottom corner',
          rightBottom: 'Right bottom corner',
          disabled: 'OFF',
        },
        chatbotMsgInterval: {
          every15mins: 'Every 15 minutes',
          every30mins: 'Every 30 minutes',
          everyHour: 'Every hour',
          disabled: 'OFF',
        },
      },
      deactivated: {
        title: 'Your account is deactivated',
        headline: 'Your profile and account details will be&nbsp;hidden until <strong>{date}</strong> and deleted on&nbsp;that date.',
        textBlock1: 'Your Uplify account is&nbsp;deactivated, what does it&nbsp;mean?',
        textBlock2: [
          'The profile, public page, widget are hidden from the platform, and will no&nbsp;longer be&nbsp;visible to&nbsp;other users.',
          'Any type of&nbsp;campaigns will not be&nbsp;available.',
          'The balance will be&nbsp;available until the account is&nbsp;deleted and is&nbsp;only payable when the minimum threshold is&nbsp;reached.',
          'Uplify will keep the account&rsquo;s data while it&nbsp;is&nbsp;deactivated. The account owner can reactivate the account at&nbsp;any time during this period.',
          'If&nbsp;the account is&nbsp;not reactivated within a&nbsp;certain period of&nbsp;time, Uplify may delete the account&rsquo;s data permanently. Uplify states that this process can take up&nbsp;to&nbsp;40&nbsp;days.',
          'Uplify also states that they may retain certain information from deactivated accounts if&nbsp;it&nbsp;is&nbsp;necessary to&nbsp;comply with legal obligations, resolve disputes, prevent fraud, or&nbsp;enforce their policies.',
        ],
        revokeBtn: 'Revoke',
      },
    },
    partner: {
      campaigns: {
        header: {
          title: {
            'brand-awareness': 'Sponsorship campaigns',
            performance: 'Interactive campaigns',
            preroll: 'Preroll campaigns',
          },
        },
        balance: 'Balance',
        tabs: {
          'brand-awareness': 'Awareness',
          performance: 'Interactive',
          preroll: 'Preroll',
        },
        types: {
          campaign: 'Campaigns',
          group: 'Groups',
          creative: 'Creatives',
        },
        tables: {
          actions: {
            downloadReport: 'Download Report',
            viewReport: 'View report',
            duplicate: 'Duplicate',
            remove: 'Remove',
          },
          columns: {
            name: 'Name',
            state: 'Status',
            disabledStatusReason: {
              closed: 'Contact support to turn on',
              parentIsDisabled: 'Parent disabled',
            },
            clicks: 'Clicks',
            ctr: 'CTR',
            impressions: 'Views',
            reach: 'Reach',
            id: 'ID',
            relatedCampaign: 'Related Campaign',
            relatedGroup: 'Related Group',
            format: 'Format',
            budget: 'Budget',
            budgetUsed: 'Budget Used',
            totalBudget: 'Total Budget',
            advertiser: 'Advertiser',
            startedAt: 'Started At',
            endedAt: 'Ended At',
            platform: 'Platform',
            moderation: 'Moderation',
            moderationStatus: {
              pending: 'Pending',
              complete: 'Complete',
            },
            filterLabel: 'Columns settings',
            channels: 'Channels',
            daysRemaining: 'Days Remaining',
            externalId: 'External ID',
            bid_cap: 'Bid cap',
            bid_cpa: 'Bid CPA',
            actions: 'Actions',
          },
          noCampaigns: 'You have no campaigns',
          noGroups: 'You have no groups',
          noCreatives: 'You have no creatives',
        },
        newCreative: {
          title: 'New creative',
          description: 'Select the group that the creative will belong to',
        },
        newGroup: {
          title: 'New group',
          description: 'Select the campaign that the group will belong to',
        },
      },
      balance: {
        header: {
          title: 'Balance',
        },
        info: {
          title: 'Current Balance',
          howFundUpBalance: 'How to top up balance?',
        },
        history: {
          title: 'Payments History',
          table: {
            columns: {
              date: 'Date',
              amount: 'Amount',
              service: 'Service',
            },
          },
          noPayments: 'No payments yet',
        },
      },
      creators: {
        header: {
          title: 'Agency',
        },
        tabs: {
          overview: 'Overview',
          history: 'Transaction history',
          campaignsActive: 'Campaigns',
          campaignsClosed: 'Closed',
          creators: 'Creators',
          billing: 'Billing',
        },
        invite: {
          title: 'Invite Creators',
          invited: 'Creators invited',
          amount: 'Amount to be paid',
          link: 'Invitation link',
          advice: {
            title: 'Get a share of revenue',
            description: 'Generate revenue share from your creators you refer for as long as their is active at Uplify.',
            link: {
              url: '/',
              label: 'How it works',
            },
          },
        },
        creatorsList: 'Creators list',
        billing: {
          companyInformation: {
            title: 'Company information',
            name: {
              label: 'Name',
              placeholder: 'Bussines name',
            },
            email: {
              label: 'E-mail',
              placeholder: 'email@email.com',
            },
            address: {
              label: 'Address',
              placeholder: 'Company address',
            },
            phone: {
              label: 'Phone',
              placeholder: '+1 (123) 456 789',
            },
          },
          paymentDetails: {
            title: 'Payment details',
            bankName: {
              label: 'Bank name',
              placeholder: '',
            },
            bankAccountName: {
              label: 'Bank account name',
              placeholder: '',
            },
            bankAccountHolderAddress: {
              label: 'Bank account holder address',
              placeholder: '',
            },
            bankAddress: {
              label: 'Bank address',
              placeholder: '',
            },
            bankAccountNumber: {
              label: 'Bank account number',
              placeholder: '',
            },
            BIC: {
              label: 'BIC / SWIFT code',
              placeholder: '',
            },
            routingNumber: {
              label: 'Routing number',
              placeholder: '',
            },
          },
          invoices: {
            title: 'Invoices',
            date: 'Date',
            amount: 'Amount',
            invoice: 'Invoice',
          },
        },
        history: {
          title: 'Transaction history',
          payments: 'Transaction for all time',
          none: 'There are no transaction',
          columns: {
            date: 'Date',
            payment: 'Payment per day',
            details: 'Details',
          },
          detailed: {
            title: 'Transaction detalisation',
            description: 'Creators who brought in money that day.',
          },
        },
        list: {
          title: 'Creators list',
          description: 'List of creators you invited. Payments for all time ',
          none: 'There are no creators',
          columns: {
            creator: 'Creator',
            lastActivity: 'Last day of activity',
            earnings: 'Earnings',
          },
        },
        creatorsPayout: {
          title: 'Creators payout',
          description: 'Please enter the amount that your creators should receive when they place sponsorship from your campaign. Please specify the cost per thousand views (CPM).',
          link: {
            text: 'More about creative formats',
            href: 'https://help.uplify.app/en/articles/5640856-in-stream-campaign-formats',
          },
          thirdParty: {
            checkbox: 'Allow third-party partners to place sponsorships',
            popover: {
              label: 'Tip',
              text: 'Enter the basic amount that include your commission and creators payout. Please specify the cost per thousand views (CPM).',
            },
          },
          comission: {
            label: 'Commission',
            popover: {
              label: 'Tip',
              text: 'Used for campaigns with individual agreements with sponsors or if creators payouts are not configured.',
            },
          },
          darkMarket: {
            checkbox: 'Enable increased rate for dark-market clients',
            popover: {
              label: 'Tip',
              text: 'Enter a base amount for clients from the dark-market category (gambling, casino, crypto, etc.) Please specify the cost per thousand views (CPM).',
            },
          },
          cpaLabel: 'Creators payout',
          darkMarketLabel: 'Third-party pricing (incl. fee)',
          fields: {
            video: 'Overlay 50%',
            custom: 'Custom',
            pip: 'Overlay 15%',
            interactive: 'Interactive',
            preroll: 'Pre-Roll',
          },
        },
        creatorsTable: {
          columns: {
            creators: 'Creator',
            lastActivity: 'Last activity',
            balance: 'Balance',
            campaign: 'Campaign',
          },
          status: {
            checkList: {
              label: 'Onboarding in progress',
              text: 'The streamer hasn’t yet completed the onboarding process.',
            },
            payable: {
              label: 'Check the payout settings',
              text: 'The streamer hasn’t yet setup the payout.',
            },
            lowCtr: {
              label: 'Low CTR',
              text: 'Issues with CTR across campaigns.',
            },
          },
          none: 'No data',
        },
        settings: {
          title: 'Settings',
          custom: {
            headline: 'Custom payout',
            descr: 'Please enter the custom amount that your creators should receive when they place sponsorship. Please specify the cost per thousand views (CPM).',
          },
          darkMarket: {
            headline: 'Rate for dark-market customers',
          },
          cpaLabel: 'Creator payout',
          darkMarketLabel: 'Third-party pricing (incl. fee)',
          fields: {
            video: 'Overlay 50%',
            custom: 'Custom',
            pip: 'Overlay 15%',
            interactive: 'Interactive',
          },
        },
        stats: {
          columns: {
            campaign: 'Campaign',
            creator: 'Creator',
            income: 'Income',
            views: 'Views',
            avgCtr: 'Avg. CTR',
            ctr: 'CTR',
            status: 'Status',
          },
          none: 'No data',
        },
        campaignCreators: {
          title: 'Campaign report: {title}',
        },
        creatorCampaigns: {
          title: '{streamer} campaigns',
        },
        categoriesStopList: {
          title: 'Categories stop list',
          description: 'Campaigns from the selected categories will be automatically disabled for display in the In-stream ads section for your creators.',
        },
        campaigns: {
          active: {
            title: 'Active campaigns',
            description: 'List of campaign available for your creators',
          },
          closed: {
            title: 'Closed campaigns',
            description: 'List of closed campaigns',
          },
          reportBtn: {
            full: 'Campaign report',
            short: 'Report',
          },
          none: 'Campaigns are coming soon',
        },
      },
      campaign: {
        defaultName: 'Campaign Name',
        defaultNameNew: 'New Campaign',
        group: 'Group',
        loading: 'Campaign loading ..',
        type: {
          title: 'Campaign type',
          description: 'Choose the type of campaign that suits your marketing goals.',
          comingSoon: 'Coming Soon',
        },
        types: {
          awareness: {
            title: 'Sponsorship',
            description: 'Fixed ad cost based on the CPM model',
          },
          brandLift: {
            title: 'Brand Lift',
            description: 'Research that measures the level of brand or product perception',
          },
          task: {
            title: 'Task',
            description: 'Assignment with an individual cost and direct involvement of the streamer',
          },
          performance: {
            title: 'Interactive',
            description: 'Flexible ad cost based on the CPA model',
          },
        },
        settings: {
          title: 'Campaign settings',
          advice: {
            title: 'What is this data?',
            name: {
              title: 'Campaign name',
              description: 'which will appear differently to streamers during broadcast.',
            },
            currency: {
              title: 'The currency',
              description: 'in which the placement of your campaign will be charged.',
            },
          },
          form: {
            name: {
              label: 'Campaign name',
              placeholder: 'Spring promo, for example',
            },
            externalId: {
              label: 'External ID',
              placeholder: 'For example PF-CMP-1661693993',
            },
            description: {
              label: 'Campaign description',
              placeholder: 'Describe campaign',
            },
            category: {
              label: 'Campaign category',
              placeholder: 'Select category',
              noData: 'No categories',
            },
            currency: {
              label: 'Currency',
              placeholder: 'Select currency',
            },
            schedule: {
              startedAtLabel: 'Start date',
              endedAtLabel: 'Finish date',
            },
            holding: {
              label: 'Advertiser holding',
              placeholder: 'Select holding',
            },
            advertiser: {
              label: 'Advertiser',
              placeholder: 'Select advertiser',
              noData: 'No data',
            },
            status: {
              label: 'Status',
            },
            awareness: {
              description: {
                label: 'Awareness',
                placeholder: 'Describe campaign for streamers',
              },
            },
            brandlift: {
              description: {
                label: 'Brand Lift',
                placeholder: 'Describe campaign for streamers',
              },
            },
            timezone: {
              label: 'Time zone',
              placeholder: 'Time zone',
            },
            formSendStatus: {
              updated: 'Campaign was updated',
            },
          },
        },
        analytics: {
          title: 'Advanced Settings',
          affiliateNetworks: {
            label: 'Network',
            notSelected: 'No integrations',
          },
          urlParams: {
            description: 'Additional parameters for the URL that allow you to track the performance of your campaigns.',
            paramBlock: 'Parameter',
            param: 'Parameter',
            name: 'Value',
            addParam: 'Add another parameter',
            deleteParam: 'Delete parameter',
          },
          pixel: {
            description: 'Uplify Pixel helps you track user activity on advertiser\'s site to deliver ads effectively.',
            label: 'Uplify Pixel',
            btn: 'Copy',
          },
        },
      },
      group: {
        defaultName: 'Group Name',
        defaultNameNew: 'New Group',
        loading: 'Group loading ..',
        settings: {
          title: 'Group settings',
          description: 'Group your campaigns by parameters and set targeting to track each of them efficiently',
          form: {
            name: {
              title: 'Group name',
              label: 'The name is visible only to you',
              placeholder: 'Group',
            },
            description: {
              title: 'Description of the group',
              label: 'Description of the group',
              placeholder: 'Describe the group for the author',
            },
            externalId: {
              title: 'External ID',
              placeholder: 'For example, PF-CMP-1661693993',
            },
            altName: {
              addBtn: 'Add alternative name for streamers',
              label: 'Group name for streamers',
              placeholder: 'For example, Spring Promo',
            },
            platform: {
              title: 'Platform',
            },
            format: {
              title: 'Format',
              moreAbout: 'Learn more about creative formats',
              formats: {
                fullscreen: {
                  label: 'Full screen video',
                  description: 'Mid-roll placed in live stream on full screen',
                },
                smallVideo: {
                  label: 'Video in a separate window',
                  description: 'Mid-roll placed in live stream on 1/4 screen',
                },
                custom: {
                  label: 'Customized solution',
                  description: 'Multi-format creative in a live stream on any visible area of the screen',
                },
              },
            },
            schedule: {
              title: 'Schedule',
              startedAtLabel: 'Start date',
              endedAtLabel: 'Finish date',
            },
            payType: {
              payPerImpression: 'Pay per impression',
              payPerAction: 'Pay per action',
            },
            budget: {
              title: 'Budget',
              costPerUnitLabel: 'Advertising cost per unit',
              impressionsLabel: 'Number of views',
              totalBudget: 'Total budget',
              costPerActionLabel: 'Const per action',
              budgetLabel: 'Budget',
              totalActions: 'Expected amount of actions:',
              bidCap: 'Cost per mile, CPM',
              impressions: 'Impressions',
              cpc: 'Click cost',
              clicks: 'Number of clicks',
              conversions: 'Number of conversions',
              margin: 'Uplify margin',
              agencyCommission: 'Agency commission',
              cpmPercent: 'Budget split, CPM',
              cpa: 'Conversion cost',
              creatorsPayout: 'Creators payout',
              conversion: 'Number of conversion',
              infoMessage: 'For internal usage only',
              dailyClickCap: 'Daily click cap',
              dailyConversionCap: 'Daily conversion cap',
            },
            targets: {
              title: 'Goals',
              ctrLabel: 'CTR Benchmark',
              cpaLabel: 'CPA Benchmark',
            },
            frequency: {
              title: 'Frequency',
              standard: {
                label: 'Standard',
                description: 'Standard - Recommended frequency of views with optimal reach: Suitable for long-term campaigns',
              },
              accelerated: {
                label: 'Accelerated',
                description: 'Accelerated - Frequency of views with maximum reach: suitable for short-term campaigns',
              },
              customizable: {
                label: 'Customizable',
                description: 'Customizable - frequency of views based on specific reach parameters: suitable for non-standard campaigns',
              },
              impressionsCount: 'Number of views',
              period: 'Period',
            },
            status: {
              label: 'Status',
            },
            timezone: {
              label: 'Time zone',
              placeholder: 'Time zone',
            },
            formSendStatus: {
              updated: 'Group was updated',
            },
          },
        },
        targeting: {
          title: 'Targeting',
          addition: {
            onlySelected: 'only selected',
            exceptSelected: 'all, except selected',
            excludeSelected: 'Exclude selected categories from targeting',
          },
          evaluation: {
            label: 'Audience evaluation',
            description: 'Select a broadcast language to get a preliminary estimate on the audience',
            size: 'Size',
            reach: 'Reach',
            streamersNumber: 'Number of streamers',
            howCalculated: 'How are indicators calculated?',
          },
          form: {
            agencies: {
              label: 'Selection of network',
              placeholder: 'Select agency',
              warning: {
                title: 'Your value for CPM is lower than the network price',
                text: 'The entered value for CPM is&nbsp;lower than the cost that the partner network requires. You will be&nbsp;able to&nbsp;save the campaign, but it&nbsp;will not work until the value is&nbsp;corrected.',
              },
            },
            streamers: {
              label: 'Selection of Streamers',
              placeholder: 'Select streamers',
            },
            language: {
              label: 'Translation Language',
              placeholder: 'Select language',
            },
            countries: {
              label: 'Countries',
              placeholder: 'Select country',
            },
            gender: {
              label: 'Gender',
              placeholder: 'Select gender',
            },
            age: {
              label: 'Streamers age',
              fromPlaceholder: 'from',
              toPlaceholder: 'to',
            },
            ageRestrictions: {
              label: 'Streamer has age restrictions enabled',
            },
            tags: {
              label: 'Tags',
              placeholder: 'Select tag',
            },
          },
        },
        labels: {
          title: '<strong>Sponsorship label</strong><br/>(CIS market only)',
          fields: {
            chatbot: {
              label: 'Chatbot erid ID',
            },
            creative: {
              label: 'Creative erid ID',
            },
          },
        },
        chatBot: {
          title: 'Chatbot settings',
          form: {
            productUrl: {
              label: 'Product link',
              placeholder: 'Enter link',
            },
            productUrlShort: {
              label: 'Short URL',
              placeholder: '',
            },
            chatBotText: {
              label: 'Chatbot text',
              placeholder: 'Enter text',
            },
          },
        },
        creative: {
          title: 'Data Creative',
          form: {
            productUrl: {
              label: 'Link to your product',
              placeholder: 'Enter link',
            },
            videoDescriptionText: {
              label: 'Description text',
              placeholder: 'Enter text',
            },
          },
        },
        preview: {
          title: 'Requirements',
          requirements: 'Resolution: 1920 x 1080 px.<br/>Format: MP4<br/>Size: up to {size} MB',
          errors: {
            unknown: 'Unknown error',
          },
        },
        messages: {
          groupUpdatedSuccesfully: 'Group updated succesfully',
        },
        analytics: {
          title: 'Analytics setup',
          pixelClicks: {
            label: 'Pixel per click',
            placeholder: 'Enter link',
            addLabel: 'Add one more tracker',
          },
          pixelClicksScripts: {
            label: 'Code pixel',
            placeholder: 'Enter code',
          },
          erid: {
            title: 'ERID (CIS market only)',
            label: 'erid ID',
            placeholder: '',
          },
        },
      },
      creative: {
        defaultName: 'Creative Name',
        defaultNameNew: 'New Creative',
        loading: 'Creative loading ..',
        form: {
          name: {
            title: 'Creative name',
            label: 'The name is visible only to you',
            placeholder: 'Enter text',
          },
          adtag: {
            title: 'Google Ad Tag',
            label: '',
            placeholder: '',
          },
          status: {
            label: 'Status',
          },
          files: {
            title: 'Upload files',
            preview: 'Upload preview file',
            formats: {
              video: 'Video',
              videoOrImage: 'Video or image',
              html5: 'HTML5',
              banner: 'Banner',
            },
            requirements: {
              title: 'Requirements',
              fullscreen: 'Duration: 15s<br/>Resolution: 1920 x 1080<br/>Format: H.264 (MP4, webm)<br/>Audio: -9dB<br/>Bitrate: 2000 - 5500 kbps<br/>Frame rate: 24 - 30<br/>Size: up to 10 MB',
              leaderboard: 'Duration: 15s<br/>Resolution: 1920 x 270<br/>Format: H.264 (MP4, webm)<br/>Audio: Disabled<br/>Bitrate: 2000 - 5500 kbps<br/>Frame rate: 24 - 30<br/>Size: up to 5 MB',
              pip_video: 'Duration: 15s<br/>Resolution: from 550 x 310<br/>up to 1920 x 1080 pix.<br/>Format: H.264 (MP4, webm)<br/>Audio: Disabled<br/>Bitrate: 2000 - 5500 kbps<br/>Frame rate: 24 - 30<br/>Size: up to 5 MB',
              custom: 'Duration: 15s<br/>Resolution: 1920 x 1080<br/>Format: PNG, JPG or GIF<br/>Third party HTML: HTML5 zip file<br/>Audio: Disabled<br/>Frame rate: 24 - 30<br/>Size: up to 10 MB',
            },
            instructions: {
              dragImage: 'Drag picture here',
              dragImageOrVideo: 'Drag an image or video here',
              dragVideo: 'Drag video here',
              dragZip: 'Drag the archive with files here',
            },
            errors: {
              general: 'Provide valid creative file',
            },
          },
          creativeManager: {
            messages: {
              deleted: 'Attachment was deleted',
            },
            errors: {
              unknown: 'Unknown error',
            },
          },
          advice: {
            title: 'Creative preview',
            description: 'Estimate how your ad will look',
            button: 'Show preview',
          },
          data: {
            title: 'Data creative',
            fields: {
              erid: {
                title: 'ERID (CIS market only)',
                label: 'erid ID',
                placeholder: '',
              },
              productUrl: {
                label: 'Link to your product',
                placeholder: 'Enter link',
              },
              chatbotText: {
                label: 'Chat bot text',
                placeholder: 'Enter text',
              },
              companionHeading: {
                label: 'Companion banner title',
                placeholder: 'Enter title',
              },
              companionText: {
                label: 'Companion banner text',
                placeholder: 'Enter text',
              },
              companionCta: {
                label: 'Call to action',
                placeholder: 'Enter text',
              },
              qrCode: {
                label: 'Generate QR-code',
              },
              pixelClicks: {
                label: 'Pixel per clicks',
                placeholder: 'Enter link',
              },
              pixelClicksScripts: {
                label: 'Code pixel',
                placeholder: 'Enter code',
              },
              pixelImpressions: {
                label: 'Pixel per view',
                placeholder: 'Enter link',
                addLabel: 'Add one more tracker',
              },
              pixelInspections: {
                label: 'Pixel for inspection',
                placeholder: 'Enter link',
                addLabel: 'Add one more tracker',
              },
            },
          },
          labels: {
            title: '<strong>Sponsorship label</strong><br/>(CIS market only)',
            fields: {
              chatbot: {
                label: 'Chatbot erid ID',
              },
              creative: {
                label: 'Creative erid ID',
              },
            },
          },
          formSendStatus: {
            updated: 'Creative was updated',
          },
        },
      },
      profile: {
        header: {
          title: 'Profile',
        },
        agency: {
          title: 'Agency',
        },
        settings: {
          title: 'About you',
        },
        password: {
          title: 'Change Password',
        },
      },
      analytics: {
        campaignOverview: 'Campaign Overview',
        creators: 'Creators',
        categories: 'Categories',
        status: 'Status',
        active: 'Active',
        inactive: 'Inactive',
        updated: 'Updated',
        downloadCSV: 'Download CSV',
        downloadExcel: 'Download Excel',
        impressions: 'Views',
        CTR: 'CTR',
        spent: 'Spent',
        fills: 'Fills',
        reach: 'Reach',
        channels: 'Channels',
        avgCPM: 'Avg. CPM',
        totalClicks: 'Total clicks',
        clicks: 'Clicks',
        adFormat: 'Format',
        outOf: 'Out of',
        creator: 'Creator',
        category: 'Category',
        runATestCampaignToday: 'Run a test campaign today!',
        viewability: 'Viewability',
        overviewTab: {
          title: 'Campaign performance ',
          performanceTitle: 'Interactive by days',
          creativeStats: 'Creative Stats',
          allCategories: 'All categories',
          groupName: 'Group name',
          creativeName: 'Creative name',
          top5Channels: 'Top 5 channels',
          top5Categories: 'Top 5 categories',
          creatorsLinkCaption: 'Go to all creators',
          categoriesLinkCaption: 'Go to all categories',
        },
        creatorsTab: {
          title: 'Creators performance',
        },
        categoriesTab: {
          title: 'Categories performance',
          otherCategories: 'Other categories',
        },
      },
      advertisers: {
        header: {
          title: 'Advertisers',
        },
        tabs: {
          advertisers: 'Advertisers',
          holdings: 'Holdings',
        },
        advertisers: {
          table: {
            advertiser: 'Advertiser',
            currency: 'Currency',
            balance: 'Balance',
            links: 'Links (Sponsorship)',
          },
          links: {
            campaigns: 'Campaigns',
            groups: 'Groups',
            creatives: 'Creatives',
            holdings: 'Holdings',
          },
        },
        holdings: {
          table: {
            holding: 'Holding Name',
            description: 'Description',
          },
        },
      },
    },
    widget: {
      adForSupport: 'Promotion by',
      willStartIn: 'will start in',
      poweredBy: 'Powered by Uplify',
      of: 'of',
      reloginMessage: {
        title: 'Attention, {platform} updated!',
        description: 'Your version of the widget is out of date. Please log out and log in to the dashboard.',
        streamer: {
          label: 'Attention',
          title: '{platform} updated!',
          description: 'Your version of the widget is out of date. Please log out and log in to the dashboard.',
          button: 'Relogin to dashboard',
        },
      },
      chatbotErrorMessage: {
        title: 'Chat bot is not working',
        description: 'Please reconnect the bot in&nbsp;your personal account. Step by&nbsp;step instruction: fix.uplify.app/chatbot',
      },
      resolutionErrorMessage: {
        title: 'Widget configured incorrectly',
        description: 'Please set up&nbsp;the widget following step-by-step guide: <span>fix.uplify.app/widget</span>',
      },
      tooManySubscribers: {
        title: 'The widget has been blocked',
        description: 'Please delete all copies of the widget and update the layer.',
      },
      platformDisclaimer: 'does not sponsor or endorse this promotion and is not responsible for it.',
      platformDisclaimerYoutube: 'This promotion is run by the creator, independently of YouTube.',
      freemium: {
        timeLeft: '{time} minutes left',
        votesLeft: 'Votes - {num} left',
        pollWinner: 'Winner',
        goalQrTitle: 'Support me at',
        goalTopSuppoerters: {
          day: 'Top supporters: Today',
          week: 'Top supporters: Last 7 days',
          quarter: 'Top supporters: All the time',
          alltime: 'Top supporters: All the time',
        },
        supporterWatcherSponsorVideo: 'watched sponsorship video',
        anonymous: 'Someone',
        supportedYou: 'supported you',
        chatbotMsgs: [
          'Support me by watching the ads {link}',
          'Donations without money were invented here {link}',
          'Now it’s easy to support my stream: Go to the link and watch the ads {link}',
          'Donations, tips and titbits are accepted here. No card needed {link}',
          'Support me again, even if you’ve already clicked the link {link}',
          'You don’t need a bank card when donations are free {link}',
          'Each click on this link is your support for my stream {link}',
          'Donations without SMS and registration {link}',
        ],
      },
    },
    panel: {
      tabs: {
        widget: 'Settings',
        campaigns: 'Campaigns',
      },
      states: {
        title: 'Status of modules',
      },
      params: {
        title: 'Campaign display options',
        previewCaption: 'Sponsorship preview in broadcast',
        locationCaption: 'Location on the broadcast',
      },
      campaigns: {
        title: 'Available campaigns',
        none: 'No campaigns available',
        comeBack: 'All campaigns are over today.<br>Come back tomorrow!',
      },
      error: {
        title: 'The link is out of date',
        text: 'Go to your personal cabinet for a new link',
      },
      statusesMustBeOn: 'To run campaigns, all modules must be on',
      logger: {
        copy: 'Copy log',
        copied: 'Log copied!',
      },
    },
    page404: {
      pageNotFound: 'Page not found',
      back: 'Back to the main page',
      question: 'Got a question?',
      chat: 'Chat with us',
    },
  },
}

export default dictionary
