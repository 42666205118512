/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'star-referrals': {
    width: 20,
    height: 19,
    viewBox: '0 0 20 19',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.976 1.151c-.361-.868-1.591-.868-1.952 0L7.172 5.603a1.057 1.057 0 01-.891.648l-4.807.385C.537 6.712.157 7.881.871 8.493l3.662 3.137c.301.258.432.662.34 1.048l-1.118 4.69c-.219.915.777 1.638 1.579 1.148l4.115-2.514a1.057 1.057 0 011.102 0l4.115 2.514c.803.49 1.797-.233 1.58-1.148l-1.12-4.69c-.091-.386.04-.79.341-1.048l3.662-3.137c.714-.612.334-1.781-.603-1.857l-4.807-.385a1.057 1.057 0 01-.891-.648l-1.852-4.452zm-.78 5.523a.211.211 0 00-.391 0l-.874 2.1a.211.211 0 01-.178.13l-2.268.182a.211.211 0 00-.12.37l1.727 1.48c.06.053.087.133.068.21l-.528 2.213a.211.211 0 00.316.23l1.942-1.186a.212.212 0 01.22 0l1.942 1.186c.16.098.36-.047.316-.23l-.528-2.213a.211.211 0 01.068-.21l1.728-1.48a.211.211 0 00-.121-.37l-2.268-.182a.211.211 0 01-.178-.13l-.874-2.1z" _fill="#266FFE"/>'
  }
})
