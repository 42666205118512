import { ReverseMap } from './base-types'

export enum Currency {
  USD = 'usd',
  RUB = 'rub',
  INR = 'inr',
  EUR = 'eur',
  BRL = 'brl',
  MXN = 'mxn',
}

export type CurrencyType = ReverseMap<typeof Currency>

export enum CurrencyIcon {
  USD = '$',
  RUB = '₽',
  INR = '₹',
  EUR = '€',
  BRL = 'R$',
  MXN = 'MXN',
}

export type CurrencyIconType = ReverseMap<typeof CurrencyIcon>
