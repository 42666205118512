import Client from 'lib/api/client'

import { Api } from './instance'

export class UplifyAdminClient extends Client {}

export const client = new UplifyAdminClient()

export class UplifyAdminApi extends Api {
  static client = client
  static apiUrl = process.env.VUE_APP_UPLIFY_ADMIN_API_URL
}
