/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'facebook-icon': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M9 .5a8.501 8.501 0 011.328 16.897v-5.94h1.98L12.686 9h-2.357V7.406c0-.672.329-1.328 1.385-1.328h1.072V3.986s-.973-.166-1.902-.166c-1.943 0-3.211 1.177-3.211 3.307V9H5.514v2.457h2.158v5.94A8.501 8.501 0 019 .5z" _fill="#666"/>'
  }
})
