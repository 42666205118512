/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'plus': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<path pid="0" d="M6.5 5.5H12v1H6.5V12h-1V6.5H0v-1h5.5V0h1z" _fill="#666" fill-rule="nonzero"/>'
  }
})
