import get from 'lodash/get'

type Service = ((...args: unknown[]) => void) | undefined
type ServiceName = 'ga' | 'fbq' | 'VK.Goal' | 'dataLayer' | 'heap.track' | 'heap.identify' | 'heap.addUserProperties'

const callService = (serviceName: ServiceName, ...args: unknown[]) => {
  let callsCount = 0
  const interval = setInterval(() => {
    const service = get(window, serviceName) as Service

    if (typeof service === 'function') {
      service(...args)
      clearInterval(interval)
    }
    else if (callsCount > 60) {
      clearInterval(interval)
    }
    else {
      callsCount++
    }
  }, 1000)
}

export class Analytic {
  static ga (...args: unknown[]) {
    if (process.env.NODE_ENV !== 'production') return
    callService('ga', ...args)
  }

  static fbq (...args: unknown[]) {
    if (process.env.NODE_ENV !== 'production') return
    callService('fbq', ...args)
  }

  static vkgoal (...args: unknown[]) {
    if (process.env.NODE_ENV !== 'production') return
    callService('VK.Goal', ...args)
  }

  static dataLayer (...args: unknown[]) {
    if (process.env.NODE_ENV !== 'production') return
    callService('dataLayer', ...args)
  }

  static heapTrack (...args: unknown[]) {
    if (process.env.NODE_ENV !== 'production') return
    callService('heap.track', ...args)
  }

  static heapIdentity (...args: unknown[]) {
    if (process.env.NODE_ENV !== 'production') return
    callService('heap.identify', ...args)
  }

  static heapAddUserProperties (...args: unknown[]) {
    if (process.env.NODE_ENV !== 'production') return
    callService('heap.addUserProperties', ...args)
  }
}
