import ElMessage from 'element/ElMessage'
import { Api } from 'lib/api/instance'
import { Model } from 'vue-mc'

class Streamer extends Model {
  default () {
    return {
      role: 'streamer',
    }
  }

  routes () {
    return {
      fetch: 'streamer/profile',
      save: 'streamer/profile',
    }
  }

  //* Redefinition native method
  async save (data = {}) {
    const url = this.getRoute('save')
    const { attributes } = this
    const patch = {
      birthday: attributes.birthday,
      language: attributes.language,
      gender: attributes.gender,
      email: attributes.email,
      country: attributes.country,
      domain: attributes.domain,
      ...data,
    }

    this.onSave()

    try {
      const res = await Api.post(url, patch)

      if (res.status) {
        this.onSaveSuccess()
      }
      else {
        this.onSaveFailure()
      }
      return res
    }
    catch (err) {
      this.onSaveFailure()
    }
  }
}

export default Streamer
