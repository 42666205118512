/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'level-0': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M22 16a6 6 0 11-12 0 6 6 0 0112 0z" _stroke="#CCC" stroke-width="4"/>'
  }
})
