/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'refresh': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<g _fill="#1A1A1A" fill-rule="nonzero"><path pid="0" d="M6 0c1.517 0 2.946.567 4.04 1.566l.202.192-.707.707a5 5 0 101.46 3.752L11 6h1a6 6 0 11-6-6z"/><path pid="1" d="M12 4V0L8 4z"/></g>'
  }
})
