import Partner from 'models/Partner'

import { type RoleType } from 'lib/types/role'

import { StreamerPayload } from './types/streamer-payload'

export const state = {
  user: undefined,
  twitch: undefined,
  youtube: undefined,
  trovo: undefined,
  discord: undefined,
  role: undefined,
  tier: undefined,
  partner: new Partner(),
  debugActive: false,
}

export type State = {
  user: object | undefined
  twitch: StreamerPayload['twitch'] | undefined
  youtube: StreamerPayload['youtube'] | undefined
  trovo: StreamerPayload['trovo'] | undefined
  discord: StreamerPayload['discord'] | undefined
  role: RoleType | undefined
  tier: string | null
  partner: Partner
  debugActive: boolean
  roleExtended: boolean
}
