/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'x_x': {
    width: 152,
    height: 40,
    viewBox: '0 0 152 40',
    data: '<g _fill="#266FFE" fill-rule="nonzero"><path pid="0" d="M3.414.586l32 32-2.828 2.828-32-32z"/><path pid="1" d="M32.586.586l2.828 2.828-32 32-2.828-2.828zM119.414.586l32 32-2.828 2.828-32-32z"/><path pid="2" d="M148.586.586l2.828 2.828-32 32-2.828-2.828zM55.261 36.141c15.42-6.13 24.638-6.249 40.18-.49l1.273.48-1.428 3.737c-14.972-5.725-23.193-5.922-37.321-.49l-1.226.48-1.478-3.717z"/></g>'
  }
})
