import { Domain } from 'lib/types/domain'

export const enableRollBar = () => {
  switch (window.location.origin) {
    case Domain.LOCAL:
    case Domain.LOCAL_S:
    case Domain.LOCAL_DOMAIN:
    case Domain.LOCAL_DOMAIN_S:
    case Domain.ALPHA:
    case Domain.BETA:
    case Domain.RELEASE:
      return false
    default:
      return true
  }
}
