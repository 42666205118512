/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'quarter-screen': {
    width: 22,
    height: 18,
    viewBox: '0 0 22 18',
    data: '<path pid="0" d="M20 0H2C.9 0 0 .9 0 2v14c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2zm0 8h-9V2h9v6z" _fill="#fff"/>'
  }
})
