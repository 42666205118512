/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'discord-plain-icon': {
    width: 32,
    height: 24,
    viewBox: '0 0 32 24',
    data: '<path pid="0" d="M26.736 2.137A25.55 25.55 0 0020.43.181a.096.096 0 00-.101.048 17.82 17.82 0 00-.785 1.613 23.586 23.586 0 00-7.083 0A16.316 16.316 0 0011.663.23a.1.1 0 00-.101-.048 25.477 25.477 0 00-6.306 1.956.09.09 0 00-.042.036C1.198 8.173.097 14.027.637 19.807a.106.106 0 00.04.073 25.692 25.692 0 007.737 3.91.1.1 0 00.108-.035 18.364 18.364 0 001.583-2.575.098.098 0 00-.054-.136 16.91 16.91 0 01-2.417-1.152.1.1 0 01-.01-.165c.163-.122.326-.248.48-.376a.096.096 0 01.1-.014c5.071 2.316 10.56 2.316 15.571 0a.096.096 0 01.101.013c.155.128.318.255.482.377a.1.1 0 01-.009.165c-.772.451-1.574.832-2.418 1.15a.099.099 0 00-.053.138c.466.901.997 1.76 1.582 2.573.024.035.068.05.109.037a25.606 25.606 0 007.748-3.91.1.1 0 00.04-.072c.646-6.683-1.082-12.488-4.58-17.634a.078.078 0 00-.04-.037zm-15.874 14.15c-1.526 0-2.784-1.401-2.784-3.122 0-1.721 1.233-3.123 2.784-3.123 1.563 0 2.809 1.414 2.784 3.123 0 1.721-1.233 3.122-2.784 3.122zm10.295 0c-1.527 0-2.785-1.401-2.785-3.122 0-1.721 1.234-3.123 2.785-3.123 1.563 0 2.808 1.414 2.784 3.123 0 1.721-1.221 3.122-2.784 3.122z" _fill="#fff"/>'
  }
})
