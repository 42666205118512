/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'creative-video': {
    width: 22,
    height: 18,
    viewBox: '0 0 22 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2 0h18c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2V2C0 .9.9 0 2 0zm13.5 9l-7 4.5v-9l7 4.5z" _fill="#fff"/>'
  }
})
