import { Api } from 'lib/api'
import {
  IStreamerReferralHistoryPayload,
  IStreamerSaveReferralPayload,
} from 'lib/types/payloads/streamer-referral'
import { IResponse, IResponseData, IResponseMessage } from 'lib/types/response'
import { ISreamerReferralProgress, IStreamerReferral, IStreamerReferralPanel } from 'lib/types/streamer-referral'

export const getReferral = () => {
  const locale = localStorage.getItem('locale')
  return Api
    .get<IResponseData<IStreamerReferral>>(`streamer/referral?locale=${locale}`)
    .then(res => res)
}

export const updateReferral = (data: IStreamerSaveReferralPayload) => {
  return Api
    .post<IResponseMessage>('streamer/referral/save', data)
    .then(res => res)
}

export const getReferralPanels = () => {
  return Api
    .get<IResponseData<IStreamerReferralPanel[]>>('streamer/referral/panels')
    .then(res => res)
}

export const getReferralHistory = () => {
  return Api
    .get<IResponse<IStreamerReferralHistoryPayload>>('streamer/referral/history')
    .then(res => res)
}

export const requestReferralDemo = () => {
  return Api
    .get<IResponseMessage>('widget/referral/demo')
    .then(res => res)
}

export const getReferralProgress = () => {
  return Api
    .get<IResponseData<ISreamerReferralProgress>>('streamer/referral/progress')
    .then(res => res)
}
