/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'level-4': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M16 4l3.527 7.146 7.886 1.146-5.707 5.562 1.347 7.854L16 22l-7.053 3.708 1.347-7.854-5.707-5.562 7.886-1.146L16 4z" _fill="#13BF34"/>'
  }
})
