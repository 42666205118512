import axios, { AxiosError, AxiosInstance } from 'axios'

import { vm } from '@/main'
import type { MethodType } from 'lib/api/types'
import { AUTH_IS_DEMO, getToken, isDemoTokenExpired, removeToken, UPLIFY_ADMIN_TOKEN } from 'lib/helpers/authToken'

const blockedURLs = [
  'ads/widget/referral/demo',
  'ads/widget/demo',
  'auth/nightbot',
]

export default class Client {
  private static instance: Client
  readonly axios: AxiosInstance

  constructor () {
    this.axios = axios.create()

    this.axios.defaults.headers.common['x-uplify-domain'] = window.location.host

    this.axios.interceptors.request.use(request => {
      const isDemoStreamer = localStorage.getItem(AUTH_IS_DEMO)
      const isLocaleRequest = request.url?.includes('/locale/save')

      if (isDemoStreamer) {
        if (isDemoTokenExpired()) {
          removeToken()
        }

        if (!isLocaleRequest) {
          const isPost = request.method === 'post'
          const isBlockedUrl = blockedURLs.find(url => request.url?.includes(url))

          if (isPost || isBlockedUrl) {
            throw new axios.Cancel('DEMO_REQUEST')
          }
        }
      }

      return request
    })

    this.axios.interceptors.response.use(
      response => {
        return response
      },
      (error: AxiosError) => {
        // vm.$rollbar.critical(error)
        const isFreemium = error.config.url?.includes(process.env.VUE_APP_FREEMIUM_URL as string)
        const isLinkAdmin = error.config.url?.includes(process.env.VUE_APP_UPLIFY_ADMIN_API_URL as string)
        const is401 = error?.response?.status === 401

        if (isLinkAdmin) {
          localStorage.removeItem(UPLIFY_ADMIN_TOKEN)
          return error.response
        }

        if (is401 && getToken() && !isFreemium && !isLinkAdmin) {
          removeToken()
        }

        return Promise.reject(error)
      },
    )
  }

  // public static getInstance () {
  //   if (!Client.instance) {
  //     Client.instance = new Client()
  //   }

  //   return Client.instance
  // }

  public setAuthToken (token: string) {
    this.axios.defaults.headers.common.Authorization = token
  }

  public get<RequestData> (url: string, params: RequestData) {
    return this.axios({
      method: 'get',
      url,
      params,
    })
  }

  public post<RequestData> (url: string, data: RequestData) {
    return this.axios({
      method: 'post',
      url,
      data,
    })
  }

  public put<RequestData> (url: string, data: RequestData) {
    return this.axios({
      method: 'put',
      url,
      data,
    })
  }

  public delete<RequestData> (url: string, data: RequestData) {
    return this.axios({
      method: 'delete',
      url,
      data,
    })
  }

  public request<RequestData> (
    method: MethodType,
    url: string,
    data: RequestData,
  ) {
    if (method === 'get') {
      return this.get(url, data)
    }
    if (method === 'post') {
      return this.post(url, data)
    }
    if (method === 'put') {
      return this.put(url, data)
    }
    if (method === 'delete') {
      return this.delete(url, data)
    }
    throw new Error('Undefined request method')
  }
}
