/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'yandex-icon': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.096 21.583V24h4.05v-2.935c0-3.97-.69-5.767-2.284-9.22L6.424 0H1.992L8.4 13.848c1.315 2.832 1.697 4.454 1.697 7.735zM17.749 0l-5.056 11.604h4.19L22.01 0h-4.26z" _fill="#fff"/>'
  }
})
