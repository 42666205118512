import { streamerFreemiumModelToAlertsAdapter } from 'lib/adapters/streamer/streamerFreemiumAdapters'
import { Api } from 'lib/api'
import { IResponseData, IResponseMessage } from 'lib/types/response'
import { IFreemiumAlerts } from 'lib/types/streamer-freemium/freemium-alerts'

export const getAlerts = async () => {
  return Api
    .get<IResponseData<IFreemiumAlerts>>('streamer/freemium/alerts')
    .then(res => res)
}

export const updateAlerts = async (data: IFreemiumAlerts) => {
  const formattedData = streamerFreemiumModelToAlertsAdapter(data)
  return Api
    .post<IResponseMessage>('streamer/freemium/alerts', formattedData)
    .then(res => res)
}

export const requestDemo = async () => {
  return Api
    .get('streamer/freemium/demo')
    .then(res => res)
}

// export const requestDemoSupportAlert = async () => {
//   return Api
//     .post('streamer/freemium/alerts/support/demo')
//     .then(res => res)
// }

// export const requestDemoGoalAlert = async () => {
//   return Api
//     .post('streamer/freemium/alerts/goal/demo')
//     .then(res => res)
// }

// export const requestDemoPollAlert = async () => {
//   return Api
//     .post('streamer/freemium/alerts/poll/demo')
//     .then(res => res)
// }
