/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'home-page-vector': {
    width: 230,
    height: 116,
    viewBox: '0 0 230 116',
    data: '<path pid="0" d="M4.257 1C2.33 24.686 11.05 48.622 37.036 60.385c29.07 13.153 76.725 8.216 84.82-10.024 3.426-7.715-2.242-13.992-8.809-13.992-6.568 0-9.788 3.998-10.766 9.995-1.958 14.99 8.877 25.695 18.596 34.98 13.272 12.682 46.217 27.963 97.123 25.364" _stroke="#006DFF" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M213.841 97.85c4.738 6.16 8.178 7.03 14.097 8.14-4.709 1.77-8.754 3.49-13.661 8.59" _stroke="#006DFF" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
