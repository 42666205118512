import {
  ICampaignDetailsPayload,
  ICampaignPayload,
  ICampaignsPayload,
  ICompletedCampaignsPayload,
} from 'lib/types/payloads/streamer-campaigns/brand-awareness'
import {
  IStreamerAwarenessCampaign,
  IStreamerAwarenessCampaignDetails,
  IStreamerAwarenessCampaigns,
  IStreamerCompletedAwarenessCampaigns,
} from 'lib/types/streamer-campaigns/brand-awareness'

import { fromStreamerAdAdapter } from './fromStreamerAdAdapter'

export const fromCampaignAdapter = (payload: ICampaignPayload): IStreamerAwarenessCampaign => ({
  id: payload.id,
  slug: payload.slug,
  title: payload.title,
  format: payload.format,
  description: payload.description,
  logo: payload.logo,
  impressions: payload.impressions,
  limit: {
    daily: payload.daily_limit,
    restPercent: payload.rest_limit_percent,
    creatorDaily: payload.creator_daily_limit,
    creatorGlobal: payload.creator_global_limit,
    creatorCampaign: payload.creator_campaign_limit,
  },
  estimateIncome: payload.estimate_income,
  ctr: payload.ctr,
  start: payload.start,
  end: payload.end,
  status: payload.status,
  restore: payload.restore,
  currency: payload.currency,
  blocked_at: payload.blocked_at,
  blocked_reason: payload.blocked_reason,
  blocked_until: payload.blocked_until,
  dailyActionLimit: {
    enabled: payload.daily_action_limit.enabled,
    today: payload.daily_action_limit.today,
    todayLimit: payload.daily_action_limit.today_limit,
  },
})

export const fromCapaignsAdapter = (payload: ICampaignsPayload): IStreamerAwarenessCampaigns => {
  return {
    active: payload.active.map(item => fromCampaignAdapter(item)),
    inactive: payload.inactive.map(item => fromCampaignAdapter(item)),
    future: payload.future.map(item => fromCampaignAdapter(item)),
    unavailable: payload.unavailable.map(item => fromCampaignAdapter(item)),
  }
}

export const fromCompletedCampaignsAdapter = (payload: ICompletedCampaignsPayload): IStreamerCompletedAwarenessCampaigns => {
  const items = payload.data.map(item => ({
    id: item.id,
    slug: item.slug,
    title: item.title,
    description: item.description,
    platform: item.platform,
    logo: item.logo,
    income: item.income,
    estimateIncome: item.estimate_income,
    ctr: item.ctr,
    avgCtr: item.avg_ctr,
    streamers: item.streamers,
    start: item.start,
    end: item.end,
    participated: item.participated,
    status: item.status,
    currency: item.currency,
  }))

  return items
}

export const fromCampaignDetailsAdapter = (payload: ICampaignDetailsPayload): IStreamerAwarenessCampaignDetails => ({
  slug: payload.slug,
  title: payload.title,
  income: payload.income,
  estimateIncome: payload.estimate_income,
  ctr: payload.ctr,
  avgCtr: payload.avg_ctr,
  totalImpressions: payload.total_impressions,
  impressionsLimit: payload.impressions_limit,
  clicks: payload.clicks,
  start: payload.start,
  end: payload.end,
  creatorPayout: payload.creator_payout,
  payoutType: payload.payout_type,
  bidCap: payload.bid_cap,
  currency: payload.currency,
  frequency: payload.frequency,
  format: payload.format,
  description: payload.description,
  ads: payload.ads.map(ad => fromStreamerAdAdapter(ad)),
  campaign: payload.campaign,
  holding: payload.holding,
  advertiser: payload.advertiser,
  status: payload.status,
  dailyActionLimit: {
    enabled: payload.daily_action_limit.enabled,
    current: payload.daily_action_limit.current,
    actionPrice: payload.daily_action_limit.action_price,
    totalEarned: payload.daily_action_limit.total_earned,
  },
})
