import EventEmitter from 'events'
import { Api } from 'lib/api/instance'
import cloneDeep from 'lodash/cloneDeep'

const initState = (schema, sourceData) => {
  return Object.keys(schema).reduce((acc, key) => {
    if (sourceData.hasOwnProperty(key)) {
      return {
        ...acc,
        [key]: sourceData[key],
      }
    }
    return {
      ...acc,
      [key]: schema[key],
    }
  }, {})
}

const proxyProperties = function () {
  Object.keys(this.schema).forEach(key => {
    if (!(key in this.aliases)) {
      this.aliases[key] = key
    }
    const alias = this.aliases[key]
    this._[alias] = cloneDeep(this.model[key])

    Object.defineProperty(this, alias, {
      get: () => this.model[key],
      set: (value) => {
        this.model[key] = value
        this.emit(`change-${alias}`, value)
        this.emit('change-prop', {
          prop: alias,
          value,
        })
      },
      enumerable: true,
    })
  })
}

export default class BaseModel extends EventEmitter {
  constructor (data) {
    super()
    this.dataKey = 'data' // key for data from backend
    this.init(data)
  }

  init (data = {}) {
    const {
      aliases = {},
      routes = {},
      schema = {},
    } = this.constructor

    this.model = initState(schema, data) // use for push to backend, matches the backend model
    this.schema = { ...schema } // contain all fields (of backend model) and default values
    this._ = {} // use for static view, updates after success updating
    this.aliases = { ...aliases }
    this.routes = { ...routes }
    this._pending = false
    this._bootstrapped = false
    this._origin = { ...data }

    proxyProperties.call(this)
  }

  get pending () {
    return this._pending
  }

  get bootstrapped () {
    return this._bootstrapped
  }

  setBootstrapped () {
    this._bootstrapped = true
  }

  fetch (url) {
    return new Promise((resolve, reject) => {
      this.emit('fetching')
      this._pending = true
      const route = url || this.routes.fetch

      Api.get(route)
        .then(res => {
          this.setBootstrapped()
          this.updateData(res[this.dataKey])
          this.emit('fetch-success', res)
          resolve(res)
        })
        .catch(err => {
          this.emit('fetch-error', err)
          reject(err)
        })
        .finally(() => {
          this._pending = false
        })
    })
  }

  update () {
    return new Promise((resolve, reject) => {
      this.emit('updating')
      this._pending = true

      Api.post(this.routes.update, this.model)
        .then((res) => {
          this.emit('update-success', res.data)
          resolve(res)
        }).catch(err => {
          this.emit('update-error', err)
          reject(err)
        }).finally(() => {
          this._pending = false
        })
    })
  }

  updateData (data) {
    Object.keys(data).forEach(key => {
      const alias = this.aliases[key]
      this.model[key] = data[key]
      this._[alias] = cloneDeep(data[key])
    })
  }
}
