import { fromPayloadAdapter } from 'lib/adapters/streamer'
import { Api } from 'lib/api'
import { IStreamerPayload, IStreamerProfilePayload } from 'lib/types/payloads/streamer'
import { IResponse, IResponseMessage } from 'lib/types/response'

export const get = () => {
  return Api
    .get<IResponse<IStreamerPayload>>('streamer/profile')
    .then(res => fromPayloadAdapter(res))
}

export const update = (data: IStreamerProfilePayload) => {
  return Api
    .post<IResponseMessage>('streamer/profile', data)
    .then(res => res)
}

export const deleteProfile = () => {
  return Api.post<IResponseMessage>('streamer/delete')
}

export const revokeProfile = () => {
  return Api.post<IResponseMessage>('streamer/delete/rollback')
}
