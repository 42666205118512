/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'friends': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M14.5 10c1.38 0 2.49-1.12 2.49-2.5S15.88 5 14.5 5a2.5 2.5 0 000 5zM7 9c1.66 0 2.99-1.34 2.99-3S8.66 3 7 3C5.34 3 4 4.34 4 6s1.34 3 3 3zm7.5 3c-1.83 0-5.5.92-5.5 2.75V17h11v-2.25c0-1.83-3.67-2.75-5.5-2.75zM7 11c-2.33 0-7 1.17-7 3.5V17h7v-2.25c0-.85.33-2.34 2.37-3.47C8.5 11.1 7.66 11 7 11z" _fill="#1A1A1A"/>'
  }
})
