/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'folder': {
    width: 16,
    height: 14,
    viewBox: '0 0 16 14',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.5 0A1.5 1.5 0 000 1.5V12a2 2 0 002 2h12a2 2 0 002-2V4a2 2 0 00-2-2H8L6 0H1.5z" _fill="#1A1A1A"/>'
  }
})
