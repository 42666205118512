import { IStreamerAd } from 'lib/types/ad'
import { IStreamerAdPayload } from 'lib/types/payloads/streamer-ad'

export const fromStreamerAdAdapter = (payload: IStreamerAdPayload): IStreamerAd => ({
  id: payload.id,
  slug: payload.slug,
  title: payload.title,
  attachments: payload.attachments,
  productUrl: payload.product_url,
  chatbotText: payload.chatbot_text,
})
