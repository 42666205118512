import { getCurrentInstance } from 'vue'
import { LocaleMessages } from 'vue-i18n'

import { Logger } from 'lib/helpers/handleLog'

function assertLocaleObj (locale: unknown): asserts locale is LocaleMessages {
  if (typeof locale !== 'object' || locale === null) {
    throw new Error()
  }
}

function assertLocale (locale: unknown, path: string): asserts locale is string {
  if (typeof locale !== 'string') {
    throw new Error()
  }
  if (locale.includes(path)) {
    throw new Error()
  }
}

function getFullPath (app: ReturnType<typeof getCurrentInstance>, path: string): string {
  const role = app?.proxy.$store.getters['auth/role'] || 'auth'
  let page = app?.proxy.$route?.meta?.locale || app?.proxy.$route.name

  if (page === 'campaigns-livestream' || page === 'campaigns-preroll') {
    page = 'campaigns'
  }

  return `views.${role}.${page}.${path}`
}

export function useLocaleObj (path: string, params?: Record<string, unknown>): LocaleMessages {
  const app = getCurrentInstance()
  const fullPath = getFullPath(app, path)
  const result = app?.proxy.$t(fullPath, { ...params })

  try {
    assertLocaleObj(result)
    return result
  }
  catch {
    Logger.warning(`There is no locale in useLocaleObj: ${fullPath}`, false, {
      result,
      fullPath,
      path,
      params,
    })

    return {}
  }
}

export function useLocale (path: string, params?: Record<string, unknown>): string {
  const app = getCurrentInstance()
  const fullPath = getFullPath(app, path)
  const result = app?.proxy.$t(fullPath, { ...params })

  try {
    assertLocale(result, fullPath)
    return result
  }
  catch {
    if (typeof result === 'object') {
      console.warn(`Have to use useLocaleObj: ${fullPath}`) // eslint-disable-line
    }
    else {
      Logger.warning(`There is no locale in useLocale: ${result}`, false, {
        path,
        fullPath,
        params,
      })
    }

    // нужно для поддержания совместимости, т.к. многие useLocale на проекте сейчас возвращают объект
    return result as string
  }
}

export function useRootLocale (path: string, params?: Record<string, unknown>): string {
  const app = getCurrentInstance()
  const result = app?.proxy.$t(path, { ...params })

  try {
    assertLocale(result, path)
    return result
  }
  catch {
    if (typeof result === 'object') {
      console.warn(`Have to use useLocaleObj: ${path}`) // eslint-disable-line
    }
    else {
      Logger.warning(`There is no locale in useLocale: ${result}`, false, {
        path,
        params,
      })
    }

    // нужно для поддержания совместимости, т.к. многие useLocale на проекте сейчас возвращают объект
    return result as string
  }
}

export function useRootLocaleObj (path: string, params?: Record<string, unknown>): LocaleMessages {
  const app = getCurrentInstance()
  const result = app?.proxy.$t(path, { ...params })

  try {
    assertLocaleObj(result)
    return result
  }
  catch {
    Logger.warning(`There is no locale in useRootLocaleObj: ${path}`, false, {
      path,
      params,
    })

    return {}
  }
}
