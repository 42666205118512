/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-left': {
    width: 9,
    height: 17,
    viewBox: '0 0 9 17',
    data: '<path pid="0" _fill="#666" fill-rule="nonzero" d="M8.367 0L9 .643 1.265 8.5 9 16.357 8.367 17 0 8.5z"/>'
  }
})
