/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'circle-video-btn': {
    width: 96,
    height: 96,
    viewBox: '0 0 96 96',
    data: '<path pid="0" d="M48 96c26.51 0 48-21.49 48-48S74.51 0 48 0 0 21.49 0 48s21.49 48 48 48z" _fill="#266FFE"/><path pid="1" d="M62.09 78.84h.06l-.7-5.77 1.55-.71.75 7.67-1.8.82-5.32-5.57 1.55-.72 3.91 4.28zM70.4 75.74l-1.22.93-4.43-5.75 1.21-.93 4.44 5.75zM73.82 72.84l-5.65-4.6 1.64-2a3.642 3.642 0 115.64 4.61l-1.63 1.99zm.57-2.79c.86-1.07.77-2.07-.49-3.09s-2.26-.9-3.12.16l-.62.76 3.61 2.93.62-.76zM73.13 61.38l1.94-4.5 1.16.5-1.33 3.09 1.6.69 1.23-2.86 1.16.5-1.23 2.86 1.6.68 1.33-3.1 1.17.5-1.94 4.52-6.69-2.88zM83.47 52.35c-.23 1.93-1.76 3.22-4.12 2.94-2.36-.28-3.53-1.91-3.29-3.83.24-1.92 1.75-3.22 4.11-2.93 2.36.29 3.53 1.91 3.3 3.82zm-6.06-.73c-.14 1.11.6 1.93 2.13 2.12 1.53.19 2.45-.44 2.58-1.55.13-1.11-.6-1.93-2.13-2.12-1.53-.19-2.45.44-2.58 1.55zM12.02 47.19l.26-1.66 5.19-.39v-.06l-4.78-2.12.23-1.42 5.21-.5v-.07l-4.81-2 .27-1.66 6.85 3.21L20.2 42l-4.92.62v.05l4.54 2.17-.23 1.49-7.57.86zM21.34 37.68l-5.22-5.65.94-1.74 7.59 1.23-.78 1.45-1.72-.31-1.24 2.31 1.21 1.26-.78 1.45zm-.43-5.25l-2.84-.51 2 2.09.84-1.58zM20.02 25.73l4.11-4.34.92.88-1.53 1.57 4.36 4.13-1.06 1.15-4.36-4.13-1.53 1.61-.91-.87zM32.62 18.64a1.47 1.47 0 00-2-.31c-1 .58-1.16 1.7-.39 3 .77 1.3 1.86 1.66 2.81 1.09a1.49 1.49 0 00.73-1.86l1.34-.79a3 3 0 01-1.4 3.83c-1.67 1-3.6.56-4.82-1.47-1.22-2.03-.65-4 1-4.95a3 3 0 014.07.66l-1.34.8zM36.82 14l1.5-.35.69 2.93 3-.72-.68-2.92 1.5-.35 1.65 7.09-1.49.35-.69-2.93-3 .71.68 2.93-1.5.35L36.82 14zM60 48L42 58.4V37.61L60 48z" _fill="#fff"/>'
  }
})
