/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'circle-exclamation': {
    width: 50,
    height: 50,
    viewBox: '0 0 50 50',
    data: '<path pid="0" d="M25 .5C11.476.5.5 11.474.5 25 .5 38.522 11.476 49.5 25 49.5c13.523 0 24.5-10.977 24.5-24.5C49.5 11.474 38.522.5 25 .5zm2.45 36.75h-4.9v-4.9h4.9v4.9zm0-9.8h-4.9v-14.7h4.9v14.7z" _fill="#266FFE"/>'
  }
})
