import { payloadToWidgetAdapter } from 'lib/adapters/widgetPayloadAdapter'
import { ISspCreativePayload, ISspStreamInfo } from 'lib/types/payloads/creative'
import { IWidgetPayload } from 'lib/types/payloads/widget'
import { IResponse, IResponseData, IStatus } from 'lib/types/response'

import { Api } from '../instance'
import { SspMediaApi } from '../ssp-media-instance'
import { SspTextApi } from '../ssp-text-instance'

const showMessage = false
type FetchBrandAwarenessPayload = {
  slug: string
  data: {
    width: number
    height: number
  }
}

export const fetch = (params: { slug: string }) => {
  return Api.get<{data: IWidgetPayload}>(
    `ads/widget/${params.slug}`,
    undefined,
    { showMessage },
  ).then(res => payloadToWidgetAdapter(res.data))
}

export const enable = (params: { slug: string }) => {
  return Api.get<{ data: IStatus }>(
    `streamer/widget/${params.slug}/enable`,
    undefined,
    { showMessage },
  )
}

export const fetchBrandAwareness = (params: FetchBrandAwarenessPayload) => {
  return Api.post(
    `ads/widget/${params.slug}/auto`,
    params.data,
    {
      showMessage,
      throwOnStatusFalse: false,
    },
  )
}

export const fetchReferralPromo = (params: { slug: string }) => {
  return Api.get(
    `ads/widget/${params.slug}/referral`,
    undefined,
    { showMessage },
  )
}

export const checkForBS = (params: { slug: string }) => {
  return Api.get<IStatus>(
    `ads/brand-safety/${params.slug}/visual`,
    undefined,
    {
      showMessage,
      throwOnStatusFalse: false,
    },
  )
}

export const fetchSspMediaCreative = (params: ISspStreamInfo, isCpcRequest = false) => {
  const url = isCpcRequest ? 'ya/dsp' : 'ya/ssp'
  return SspMediaApi.post<IResponseData<ISspCreativePayload>>(
    url,
    params,
    {
      showMessage,
      throwOnStatusFalse: false,
    })
    .then(res => (res.data))
}

export const fetchSspTextCreative = (params: ISspStreamInfo) => {
  return SspTextApi.post<IResponseData<ISspCreativePayload>>(
    'ya',
    params,
    {
      showMessage,
      throwOnStatusFalse: false,
    })
    .then(res => (res.data))
}
