import { Collection } from 'vue-mc'

import Language from '@/models/Language'

class Languages extends Collection {
  defaults () {
    return {}
  }

  options () {
    return {
      model: Language,
    }
  }

  routes () {
    return {
      fetch: `${process.env.VUE_APP_API_URL}dictionary/languages`,
    }
  }
}

export default Languages
