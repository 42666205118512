/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'level-5': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M16 28l-3.527-7.146-7.886-1.146 5.707-5.562-1.347-7.854L16 10l7.053-3.708-1.347 7.854 5.707 5.562-7.886 1.146L16 28z" _fill="#13BF34"/><path pid="1" d="M16 1.13l4.254 8.62.116.235.26.038 9.512 1.382-6.883 6.71-.188.183.045.259 1.625 9.473-8.508-4.473-.233-.122-.233.122L7.26 28.03l1.624-9.473.045-.26-.188-.183-6.883-6.71 9.512-1.381.26-.038.116-.236L16 1.13z" _fill="#13BF34" _stroke="#fff"/>'
  }
})
