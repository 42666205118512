import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const DEFAULT_TITLE = 'Uplify Platform'

export default routes => {
  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
  })

  router.afterEach((to, from) => {
    Vue.nextTick(() => {
      document.title = to.meta.title || DEFAULT_TITLE
    })
  })

  return router
}
