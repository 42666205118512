import VueI18n from 'vue-i18n'

import en from './en'
import es from './es'
import pt from './pt'
import ru from './ru'

const messages: VueI18n.LocaleMessages = {
  ru,
  en,
  pt,
  es,
}

export default messages
