/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chevron-down': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.646 5.354l.708-.708L8 10.293l5.646-5.647.707.708L8 11.707 1.646 5.354z" _fill="#13BF34"/>'
  }
})
