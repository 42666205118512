/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'link': {
    width: 10,
    height: 10,
    viewBox: '0 0 10 10',
    data: '<path pid="0" d="M8.325 1.246a.416.416 0 00-.046.004H6.25a.417.417 0 100 .834h1.077L3.455 5.956a.417.417 0 10.59.589l3.872-3.872V3.75a.417.417 0 10.833 0V1.72a.417.417 0 00-.425-.474zm-6.242.004a.84.84 0 00-.833.834v5.833a.84.84 0 00.833.833h5.834a.84.84 0 00.833-.833v-2.5a.417.417 0 10-.833 0v2.5H2.083V2.084h2.5a.417.417 0 100-.834h-2.5z" _fill="#30C44E"/>'
  }
})
