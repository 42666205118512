import { Role, type RoleType } from 'lib/types/role'
import Streamer from 'models/Streamer'
import { MutationTree } from 'vuex'

import { type State } from './state'
import { MutationTypes } from './types/mutation-types'
import { StreamerPayload } from './types/streamer-payload'

export type Mutations = {
  [MutationTypes.UPDATE_STREAMER](state: State, payload: StreamerPayload): void
  [MutationTypes.UPDATE_ROLE](state: State, payload: RoleType): void
  [MutationTypes.SET_DEBUG](state: State, payload: boolean): void
  [MutationTypes.SET_EXTENDED_ROLE](state: State, payload: boolean): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.UPDATE_STREAMER] (state, payload: StreamerPayload) {
    state.role = Role.STREAMER
    // @ts-ignore
    state.user = new Streamer(payload.user)
    state.twitch = payload.twitch
    state.youtube = payload.youtube
    state.trovo = payload.trovo
    state.discord = payload.discord
    state.tier = payload.tier
  },
  [MutationTypes.UPDATE_ROLE] (state, payload: RoleType) {
    state.role = payload
  },
  [MutationTypes.SET_DEBUG] (state, payload: boolean) {
    state.debugActive = payload
  },
  [MutationTypes.SET_EXTENDED_ROLE] (state, payload: boolean) {
    state.roleExtended = payload
  },
}
