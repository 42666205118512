/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'eye': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M13.747 8a9.76 9.76 0 01-1.231 1.232C11.425 10.143 9.895 11 8 11s-3.425-.857-4.516-1.768A9.76 9.76 0 012.254 8a9.76 9.76 0 011.23-1.232 8.169 8.169 0 011.873-1.189 3 3 0 105.286 0 8.17 8.17 0 011.873 1.189c.535.446.95.894 1.23 1.232zM1 8s2.582-4 7-4 7 4 7 4-2.582 4-7 4-7-4-7-4zm7-2a1 1 0 11-2 0 1 1 0 012 0z" _fill="#666"/>'
  }
})
