/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'gear': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M13.95 8.783c.033-.25.05-.508.05-.783a4.81 4.81 0 00-.058-.783L15.633 5.9a.409.409 0 00.1-.508l-1.6-2.767c-.1-.183-.308-.242-.491-.183l-1.992.8a5.885 5.885 0 00-1.35-.784L10 .342A.403.403 0 009.6 0H6.4c-.2 0-.358.142-.392.342l-.3 2.116c-.491.2-.941.475-1.35.784l-1.991-.8a.398.398 0 00-.492.183L.283 5.392c-.1.175-.066.391.1.508l1.692 1.317A4.89 4.89 0 002 8c0 .258.017.533.058.783L.367 10.1a.409.409 0 00-.1.508l1.6 2.767c.1.183.308.242.491.183l1.992-.8c.417.317.858.584 1.35.784l.3 2.116c.042.2.2.342.4.342h3.2c.2 0 .367-.142.392-.342l.3-2.116c.491-.2.941-.467 1.35-.784l1.991.8c.184.067.392 0 .492-.183l1.6-2.767c.1-.183.058-.391-.1-.508L13.95 8.783zM8 11c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3z" _fill="#1A1A1A"/>'
  }
})
