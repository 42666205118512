import { IStremerFreemiumAlertsPayloadTo, IStremerFreemiumGoalPayloadTo } from 'lib/types/payloads/streamer-freemium'
import { IFreemiumAlerts } from 'lib/types/streamer-freemium/freemium-alerts'
import { IFreemiumGoal, IFreemiumGoalModel } from 'lib/types/streamer-freemium/freemium-setup'

export const streamerFreemiumGoalModelToPayloadAdapter = (model: IFreemiumGoalModel): IStremerFreemiumGoalPayloadTo => {
  return {
    title: model.title,
    description: model.description,
    start_value: model.startValue || 0,
    total_value: model.totalValue,
    public_total: model.publicTotal,
  }
}

export const streamerFreemiumGoalToModelAdapter = (goal: IFreemiumGoal): IFreemiumGoalModel => {
  return {
    title: goal.title,
    description: goal.description,
    startValue: goal.value,
    totalValue: goal.total,
    publicTotal: goal.publicTotal,
  }
}

export const streamerFreemiumModelToAlertsAdapter = (model: IFreemiumAlerts): IStremerFreemiumAlertsPayloadTo => {
  return {
    goal_position: model.goal.position,
    poll_position: model.poll.position,
    support_position: model.support.position,
    support_message_duration: model.support.messageDuration,
    chat: !!model.chat_message_frequency,
    chat_message_frequency: model.chat_message_frequency,
  }
}
