import Client from 'lib/api/client'

import { Api } from './instance'

export class FreemiumClient extends Client {}

export const client = new FreemiumClient()

export class FreemiumApi extends Api {
  static client = client
  static apiUrl = process.env.VUE_APP_FREEMIUM_API_URL
}
