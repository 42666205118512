/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'medium-icon': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M0 0h16v16H0V0zm3.818 5.351l-.001-.001v4.751a.548.548 0 01-.145.471l-1.129 1.37v.18h3.202v-.18l-1.13-1.37a.567.567 0 01-.155-.471V5.992l2.81 6.13h.327l2.413-6.13v4.889c0 .13 0 .155-.085.24l-.868.843v.18h4.215v-.18l-.838-.823a.25.25 0 01-.095-.241V4.854a.25.25 0 01.095-.24l.858-.824v-.18h-2.97L8.204 8.893 5.795 3.61H2.679v.18L3.683 5c.098.089.148.22.135.351z" _fill="#12100E" fill-rule="nonzero"/>'
  }
})
