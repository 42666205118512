import Client from 'lib/api/client'
import getFirstErrorMessage from 'lib/api/getFirstErrorMessage'
import type {
  CatchedErrorType,
  ErrorType,
  MethodType,
  ResponseType,
} from 'lib/api/types'

import ElMessage from 'element/ElMessage'

import getFirstErrorCode from './getFirstErrorCode'

export const client = new Client()

type OptionsType = {
  showMessage: boolean
  throwOnStatusFalse?: boolean
}

const defaultOptions: OptionsType = {
  showMessage: true,
  throwOnStatusFalse: true,
}

class Api {
  static client = client
  static apiUrl = process.env.VUE_APP_API_URL

  static get<ResponseData> (
    path: string,
    data?: unknown,
    options?: OptionsType,
  ) {
    return this.request<ResponseData>('get', path, data, options)
  }

  static post<ResponseData> (
    path: string,
    data?: unknown,
    options?: OptionsType,
  ) {
    return this.request<ResponseData>('post', path, data, options)
  }

  static put<ResponseData> (
    path: string,
    data?: unknown,
    options?: OptionsType,
  ) {
    return this.request<ResponseData>('put', path, data, options)
  }

  static delete<ResponseData> (
    path: string,
    data?: unknown,
    options?: OptionsType,
  ) {
    return this.request<ResponseData>('delete', path, data, options)
  }

  private static request<ResponseData> (
    method: MethodType,
    path: string,
    data?: unknown,
    options?: OptionsType,
  ): Promise<ResponseData> {
    const url = `${this.apiUrl}${path}`

    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res: ResponseType<ResponseData> = await this.client.request(method, url, data)

          if (url.includes(process.env.VUE_APP_UPLIFY_ADMIN_API_URL)) {
            // @ts-ignore
            resolve(res.data)
          }

          if (res.data.status) {
            resolve(res.data)
          }

          else if (res.data.status === false) {
            if (!options?.throwOnStatusFalse && !res.data.message && !res.data.messages) {
              resolve(res.data as ResponseData)
            }
            else {
              reject(this.catchError(res.data as ErrorType, options))
            }
          }
        }
        catch (err) {
          reject(this.catchError(err as Error, options))
        }
      })()
    })
  }

  private static catchError (
    error: ErrorType,
    options: OptionsType = defaultOptions,
  ): CatchedErrorType {
    const message = getFirstErrorMessage(error)
    const code = getFirstErrorCode(error)

    if (message === 'DEMO_REQUEST') {
      ElMessage('This functionality is not available for demo')
    }
    else if (options.showMessage) {
      ElMessage.error(message)
    }
    return {
      message,
      origin: error,
      code,
    }
  }
}

export { Api }
