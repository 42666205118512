/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'search-icon': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7 11a4 4 0 100-8 4 4 0 000 8zm0 1A5 5 0 107 2a5 5 0 000 10z" _fill="#666"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M10.354 9.646l3.5 3.5-.708.708-3.5-3.5.708-.708z" _fill="#666"/>'
  }
})
