/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'creative': {
    width: 20,
    height: 16,
    viewBox: '0 0 20 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.833.5a2 2 0 00-2 2v11a2 2 0 002 2h14.334a2 2 0 002-2v-11a2 2 0 00-2-2H2.833zm8.834 9.167L10 13.833 8.333 9.667 4.167 8l4.166-1.667L10 2.167l1.667 4.166L15.833 8l-4.166 1.667z" _fill="#1A1A1A"/>'
  }
})
