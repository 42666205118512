/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'home': {
    width: 16,
    height: 17,
    viewBox: '0 0 16 17',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M.683 6.402A2 2 0 000 7.908V16a1 1 0 001 1h4a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1h4a1 1 0 001-1V7.908a2 2 0 00-.683-1.506l-6-5.25a2 2 0 00-2.634 0l-6 5.25z" _fill="#266FFE"/>'
  }
})
