/* eslint-disable no-console */
import ElMessage from 'element/ElMessage'
import { ErrorMessage } from 'lib/types/error-message'

import { vm } from '@/main'

enum LogLevel {
  DEBUG = 'debug',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
  CRITICAL = 'critical',
}

type LogMessage = string | ErrorMessage

export class Logger {
  static debug (msg: LogMessage, ...args: unknown[]) {
    this.handleLog(msg, LogLevel.DEBUG, false, args)
  }

  static info (msg: LogMessage, showMessage = true, ...args: unknown[]) {
    this.handleLog(msg, LogLevel.INFO, showMessage, args)
  }

  static warning (msg: LogMessage, showMessage = true, ...args: unknown[]) {
    this.handleLog(msg, LogLevel.WARNING, showMessage, args)
  }

  static error (msg: ErrorMessage, showMessage = true, ...args: unknown[]) {
    this.handleLog(msg, LogLevel.ERROR, showMessage, args)
  }

  static critical (msg: ErrorMessage, showMessage = true, ...args: unknown[]) {
    this.handleLog(msg, LogLevel.CRITICAL, showMessage, args)
  }

  private static handleLog (msg: LogMessage, level: LogLevel, showMessage: boolean, data: unknown[]) {
    this.handleConsole(msg, level, data)
    // this.handleRollbar(msg, level, data)
    if (showMessage) {
      this.handleMessage(msg, level)
    }
  }

  private static handleConsole (msg: LogMessage, level: LogLevel, data: unknown[] = []) {
    switch (level) {
      case LogLevel.DEBUG:
        console.log(msg + ' ' + new Date().toLocaleTimeString('ru'), ...data)
        break
      case LogLevel.INFO:
        console.info(msg, ...data)
        break
      case LogLevel.WARNING:
        console.warn(msg, ...data)
        break
      case LogLevel.ERROR:
      case LogLevel.CRITICAL:
        console.error(msg, ...data)
        break
    }
  }

  private static handleMessage (msg: LogMessage, level: LogLevel) {
    switch (level) {
      case LogLevel.ERROR:
      case LogLevel.CRITICAL:
        ElMessage.error(msg)
        break
    }
  }

  private static handleRollbar (msg: LogMessage, level: LogLevel, data: unknown[] = []) {
    try {
      const isWidget = window.location.href.toLowerCase().includes('/ads/v1/wgt-')
      if (isWidget) {
        vm.$rollbar[level]('[WIDGET ERROR] ' + msg, { payload: data })
      }
    }
    catch (err) {
      console.error('Error sending error throw Rollbar', err)
    }
  }
}
