/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'download': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<g _fill="#000" fill-rule="nonzero"><path pid="0" d="M6.5 7.293l2.646-2.647.708.708L6 9.207 2.146 5.354l.708-.708L5.5 7.293V0h1z"/><path pid="1" d="M0 8v2.5A1.5 1.5 0 001.5 12h9a1.5 1.5 0 001.5-1.5V8h-1v2.5a.5.5 0 01-.5.5h-9a.5.5 0 01-.5-.5V8H0z"/></g>'
  }
})
