import { FreemiumApi } from 'lib/api/freemium-instance'
import { IStreamerFreemiumRecentSupportersPayload, IStreamerFreemiumSupportersStatisticsPayload, IStreamerFreemiumTopSupportersPayload, IStreamerFreemiumTopSupportersPeriodPayload } from 'lib/types/payloads/streamer-freemium'
import { IResponseData } from 'lib/types/response'

export const getRecentSupporters = async (streamerId: number): Promise<IStreamerFreemiumRecentSupportersPayload> => {
  return await FreemiumApi
    .get<IResponseData<IStreamerFreemiumRecentSupportersPayload>>('supporters/recent', { streamerId })
    .then(res => res.data)
}

export const getTopSupporters = async (streamerId: number): Promise<IStreamerFreemiumTopSupportersPayload> => {
  return await FreemiumApi
    .get<IResponseData<IStreamerFreemiumTopSupportersPayload>>('supporters/top', { streamerId, extended: true })
    .then(res => res.data)
}

export const getSupportersStatistics = async (streamerId: number): Promise<IStreamerFreemiumSupportersStatisticsPayload> => {
  return await FreemiumApi
    .get<IResponseData<IStreamerFreemiumSupportersStatisticsPayload>>('statistic', { streamerId })
    .then(res => res.data)
}

export const getTopSupportersByPeriod = async ({
  streamerId,
  start,
  end,
}: {
  streamerId: number
  start: string
  end: string
}): Promise<IStreamerFreemiumTopSupportersPeriodPayload> => {
  return await FreemiumApi
    .get<IResponseData<IStreamerFreemiumTopSupportersPeriodPayload>>(
      'supporters/top-period',
      { streamerId, start, end })
    .then(res => res.data)
}
