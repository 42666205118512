/* eslint-disable simple-import-sort/imports */

import Vue, { CreateElement } from 'vue'
import Rollbar from 'rollbar'
import Clipboard from 'v-clipboard'
// @ts-expect-error: no types for this module
import inputTrim from 'v-input-trim'
import { initToken } from 'lib/helpers/authToken'
import { Role } from 'lib/types/role'
import App from '@/App.vue'
import store from '@/store'
import localeMixin from 'lib/mixins/getLocaleMixin'
import elementUILocaleMixin from 'lib/mixins/setElementUILocale'
import createRouter from 'lib/helpers/createRouter'
import '@/assets/svg/build'
import 'lib/filters'
import { createPinia, PiniaVuePlugin } from 'pinia'
import { install } from 'vue-demi'
import ElMessage from 'element/ElMessage'
import SvgIcon from 'vue-svgicon'
import { i18n } from '@/i18n'
import { Domain, DomainNames, DomainName } from 'lib/types/domain'
import { enableRollBar } from 'lib/helpers/enableRollbar'
import { rollbarIgnoredMessages } from 'lib/helpers/rollbarIgnoredMessages'

// @ts-expect-error: no types for this module
import VueMarkdownEditor from '@kangc/v-md-editor'
import '@kangc/v-md-editor/lib/style/base-editor.css'

// @ts-expect-error: no types for this module
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js'
import '@kangc/v-md-editor/lib/theme/style/vuepress.css'

// @ts-expect-error: no types for this module
import createEmojiPlugin from '@kangc/v-md-editor/lib/plugins/emoji/index'
import '@kangc/v-md-editor/lib/plugins/emoji/emoji.css'

import Prism from 'prismjs'
import 'prismjs/components/prism-json'

VueMarkdownEditor.use(vuepressTheme, {
  Prism,
})
VueMarkdownEditor.use(createEmojiPlugin())

install()

initToken()

// Глобально используем только сверхчастоиспользуемые компоненты
Vue.use(SvgIcon, {
  tagName: 'SvgIcon',
})
Vue.mixin(localeMixin)
Vue.mixin(elementUILocaleMixin)

// Локально в компонент его не установить
Vue.use(Clipboard)
Vue.use(inputTrim)

Vue.prototype.$message = ElMessage
Vue.use(PiniaVuePlugin)

Vue.use(VueMarkdownEditor)

Vue.mixin({
  computed: {
    $apiURL: () => process.env.VUE_APP_API_URL,
    $isProduction: () => process.env.NODE_ENV === 'production',
    $isDevelopment: () => process.env.NODE_ENV === 'development',
    $isStreamer () {
      // @ts-expect-error: untyped $store
      return this.$store.state.auth.role === Role.STREAMER
    },
    $isPartner () {
      // @ts-expect-error: untyped $store
      return this.$store.state.auth.role === Role.PARTNER
    },
    $userDomain: () => {
      switch (window.location.origin) {
        case Domain.LOCAL:
        case Domain.LOCAL_S:
        case Domain.LOCAL_DOMAIN:
        case Domain.LOCAL_DOMAIN_S:
        case Domain.ALPHA:
        case Domain.BETA:
        case Domain.RELEASE:
        case Domain.PLATFORM:
          return DomainNames.UPLIFY
        case Domain.STREAMO:
          return DomainNames.STREAMO
        case Domain.PARETO:
        case Domain.PARETO_IO:
          return DomainNames.PARETO
        case Domain.TROVO:
          return DomainNames.TROVO
        case Domain.STREAMMONEY:
          return DomainNames.STREAMMONEY
        case Domain.GAMING_PARTNERS:
          return DomainNames.GAMING_PARTNERS
        case Domain.JONEKIRI:
          return DomainNames.JONEKIRI
      }
    },
    $isMobile: () => window.innerWidth < 1000,
  },
})

try {
  Vue.prototype.$rollbar = new Rollbar({
    enabled: enableRollBar(),
    accessToken: process.env.VUE_APP_ROLLBAR_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: window.location.host,
    captureIp: true,
    codeVersion: APPLICATION_VERSION,
    ignoredMessages: rollbarIgnoredMessages,
  })

  Vue.config.errorHandler = (err, vm) => {
    const isWidget = window.location.href.toLowerCase().includes('/ads/v1/wgt-')
    if (isWidget) {
      // @ts-expect-error: untyped $rollbar
      vm.$rollbar.error(err)
    }
    throw err
  }
}
catch (err) {
  // eslint-disable-next-line no-console
  console.error('Error connecting Rollbar', err)
}

// root routes
const routes = [
  {
    path: '/ads/:apiVersion/:slug',
    name: 'widget-page',
    meta: { title: 'Widget - Uplify' },
  },
  {
    path: '/obs-dock/:token',
    name: 'panel-page',
    meta: { title: 'Widget - Settings Panel' },
  },
  {
    path: '/creative-preview/:creativeSlug?',
    name: 'creative-preview',
    meta: { title: 'Creative Debug - Uplify' },
  },
  {
    path: '/(streamer|partner)/email/verification',
    name: 'streamer-email-verification',
    meta: { title: 'Email Verification - Uplify' },
  },
  {
    path: '/campaign-analytics/:campaignSlug',
    name: 'public-campaign-analytics',
    meta: { title: 'Campaign Analytics - Uplify' },
  },
]

const pinia = createPinia()

const VueData = {
  store,
  pinia,
  router: createRouter(routes),
  i18n,
  render: (h: CreateElement) => h(App),
}
type VueDataType = typeof VueData & {
  $rollbar: Rollbar
  $userDomain: DomainName
  $isStreamer: boolean
  $isPartner: boolean
  $isMobile: boolean
  $clipboard: (text: string) => void
 }

const vm = new Vue<VueDataType>(VueData)

// экспорт раньше маунта, т.к. в компонентах из списка выше инициализация происходит раньше,
// чем экспортируется vm
export { vm }

vm.$mount('#app')
