/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'discord-auth-icon': {
    width: 12,
    height: 14,
    viewBox: '0 0 12 14',
    data: '<path pid="0" d="M10.594 0H1.406A1.41 1.41 0 000 1.413v9.174C0 11.37.63 12 1.406 12h7.776l-.364-1.269.878.816.83.768L12 13.618V1.413A1.41 1.41 0 0010.594 0zM7.947 8.955s-.246-.294-.452-.555c.898-.254 1.241-.816 1.241-.816a3.924 3.924 0 01-.789.405c-.342.144-.672.24-.994.294a4.804 4.804 0 01-1.776-.006 5.756 5.756 0 01-1.008-.295 4.018 4.018 0 01-.5-.233c-.021-.014-.042-.021-.062-.035-.014-.007-.02-.013-.028-.02-.123-.069-.192-.117-.192-.117s.33.549 1.2.81c-.205.26-.459.568-.459.568-1.515-.048-2.091-1.042-2.091-1.042 0-2.208.987-3.998.987-3.998.987-.74 1.927-.72 1.927-.72l.068.083c-1.234.356-1.803.898-1.803.898s.15-.082.405-.199c.733-.322 1.316-.411 1.556-.432.041-.007.076-.014.117-.014a5.59 5.59 0 013.45.645c-.001 0-.543-.514-1.709-.87l.096-.11s.94-.021 1.927.72c0 0 .988 1.79.988 3.997 0 0-.583.994-2.099 1.042z" _fill="#266FFE"/><path pid="1" d="M4.783 5.927c-.38 0-.68.333-.68.74 0 .406.307.74.68.74.38 0 .68-.334.68-.74.007-.407-.3-.74-.68-.74zm2.434 0c-.38 0-.68.333-.68.74 0 .406.306.74.68.74.38 0 .68-.334.68-.74 0-.407-.3-.74-.68-.74z" _fill="#266FFE"/>'
  }
})
