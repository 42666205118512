/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'discord-icon': {
    width: 16,
    height: 18,
    viewBox: '0 0 16 18',
    data: '<g _fill="#3679FF" fill-rule="evenodd"><path pid="0" d="M6.246 7.551c-.513 0-.918.45-.918.999 0 .549.414.999.918.999.513 0 .918-.45.918-.999.009-.549-.405-.999-.918-.999zm3.285 0c-.513 0-.918.45-.918.999 0 .549.414.999.918.999.513 0 .918-.45.918-.999 0-.549-.405-.999-.918-.999z"/><path pid="1" d="M13.905 0H1.845A1.85 1.85 0 000 1.854v12.168a1.85 1.85 0 001.845 1.854h10.206l-.477-1.665 1.152 1.071 1.089 1.008L15.75 18V1.854A1.85 1.85 0 0013.905 0zm-3.474 11.754s-.324-.387-.594-.729c1.179-.333 1.629-1.071 1.629-1.071a5.15 5.15 0 01-1.035.531c-.45.189-.882.315-1.305.387a6.305 6.305 0 01-2.331-.009 7.555 7.555 0 01-1.323-.387 5.274 5.274 0 01-.657-.306c-.027-.018-.054-.027-.081-.045-.018-.009-.027-.018-.036-.027-.162-.09-.252-.153-.252-.153s.432.72 1.575 1.062c-.27.342-.603.747-.603.747-1.989-.063-2.745-1.368-2.745-1.368 0-2.898 1.296-5.247 1.296-5.247 1.296-.972 2.529-.945 2.529-.945l.09.108c-1.62.468-2.367 1.179-2.367 1.179s.198-.108.531-.261c.963-.423 1.728-.54 2.043-.567.054-.009.099-.018.153-.018a7.336 7.336 0 014.527.846s-.711-.675-2.241-1.143l.126-.144s1.233-.027 2.529.945c0 0 1.296 2.349 1.296 5.247 0 0-.765 1.305-2.754 1.368z"/></g>'
  }
})
