import { Api } from 'lib/api'
import { ICheckList } from 'lib/types/check-list'
import { IResponse, IResponseData } from 'lib/types/response'

export const get = () => {
  return Api
    .get<IResponseData<ICheckList>>('streamer/dashboard/check-list')
    .then(res => res)
}

export const close = () => {
  return Api
    .post<IResponse<null>>('streamer/dashboard/check-list/close')
    .then(res => res)
}
