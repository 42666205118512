/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'plus-circle': {
    width: 16,
    height: 16,
    viewBox: '0 0 23 23',
    data: '<circle pid="0" cx="11.5" cy="11.5" r="11.5" _fill="#BCD2FF"/><path pid="1" _stroke="#065AFD" d="M11.5 6v11m5.5-5.5H6"/>'
  }
})
