/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'eye-close': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.384 14.32l10-12-.768-.64-2.26 2.712A7.288 7.288 0 008 4C3.582 4 1 8 1 8s1.316 2.04 3.678 3.206L2.616 13.68l.768.64zm1.955-3.907a8.181 8.181 0 01-1.855-1.18A9.76 9.76 0 012.254 8a9.76 9.76 0 011.23-1.232 8.168 8.168 0 011.873-1.189 2.995 2.995 0 00.832 3.813l-.85 1.02zm1.117 1.42l.738-.885c.261.034.53.052.806.052 1.895 0 3.425-.857 4.516-1.768A9.76 9.76 0 0013.746 8a9.761 9.761 0 00-1.23-1.232 8.599 8.599 0 00-1.157-.818l.648-.778C13.937 6.352 15 8 15 8s-2.582 4-7 4c-.543 0-1.058-.06-1.544-.166zM11 7c0-.19-.018-.377-.052-.557L7.984 10H8a3 3 0 003-3zM8 6a1 1 0 11-2 0 1 1 0 012 0z" _fill="#666"/>'
  }
})
