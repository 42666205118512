/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'play': {
    width: 6,
    height: 8,
    viewBox: '0 0 6 8',
    data: '<path pid="0" d="M5.25 3.567a.5.5 0 010 .866L.75 7.031A.5.5 0 010 6.598V1.402A.5.5 0 01.75.969l4.5 2.598z" _fill="#fff"/>'
  }
})
