/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'creative-zip': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" d="M36 0H4C2 0 0 1.8 0 4v6.02c0 1.44.86 2.68 2 3.38V36c0 2.2 2.2 4 4 4h28c1.8 0 4-1.8 4-4V13.4c1.14-.7 2-1.94 2-3.38V4c0-2.2-2-4-4-4zM26 24H14v-4h12v4zm10-14H4V4h32v6z" _fill="#666"/>'
  }
})
