/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'help-icon': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M8 1C4.136 1 1 4.136 1 8s3.136 7 7 7 7-3.136 7-7-3.136-7-7-7zm.7 11.9H7.3v-1.4h1.4v1.4zm1.449-5.425l-.63.644c-.504.51-.82.93-.82 1.98H7.3v-.35c0-.77.316-1.47.82-1.98l.868-.882c.259-.252.413-.602.413-.987 0-.77-.63-1.4-1.4-1.4-.77 0-1.4.63-1.4 1.4H5.2a2.8 2.8 0 115.6 0c0 .616-.252 1.176-.651 1.575z" _fill="#266FFE"/>'
  }
})
