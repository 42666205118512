import BaseModel from '@/models/BaseModel'

export default class Partner extends BaseModel {
  constructor () {
    super()
    this.dataKey = 'user'
  }

  static routes = {
    fetch: 'partner/profile',
    update: 'partner/profile',
  }

  static schema = {
    agency: null,
    user_id: '',
    balance: 100,
    name: '',
    email: '',
    phone: '',
    company: '',
    currency: '',
    locale: '',
    email_verified: false,
    domain: 'uplify',
    debug_active: false,
    role_extended: false,
  }

  static aliases = {
    email_verified: 'emailVerified',
    user_id: 'id',
  }
}
