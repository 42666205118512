/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'collapse-minus': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3 11.25h18v1.5H3v-1.5z" _fill="#1A1A1A"/>'
  }
})
