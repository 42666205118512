import { Currency } from 'lib/types/currency'

import store from '@/store'

type ExchangeRate = {
  from_currency: Currency
  to_currency: Currency
  rate: number
}

export default (
  sum: number,
  from: Currency,
  to: Currency = store.getters['auth/currency'],
): number => {
  const rates = store.getters['dictionaries/exchangeRates']
  let result = sum

  rates.forEach((item: ExchangeRate) => {
    if (item.from_currency === from && item.to_currency === to) {
      result = item.rate * sum
    }
  })

  return result
}
