/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu-icon': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2 4h20v2H2V4zm0 7h20v2H2v-2zm20 7H2v2h20v-2z" _fill="#1A1A1A"/>'
  }
})
