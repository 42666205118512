/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'logout': {
    width: 13,
    height: 12,
    viewBox: '0 0 13 12',
    data: '<g _fill="#000" fill-rule="nonzero"><path pid="0" d="M10.293 5.5L7.646 2.854l.708-.708L12.207 6 8.354 9.854l-.708-.708L10.293 6.5H4v-1z"/><path pid="1" d="M6 0H1.5A1.5 1.5 0 000 1.5v9A1.5 1.5 0 001.5 12H6v-1H1.5a.5.5 0 01-.5-.5v-9a.5.5 0 01.5-.5H6V0z"/></g>'
  }
})
