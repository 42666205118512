import {
  fromCampaignDetailsAdapter,
  fromCapaignsAdapter,
  fromCompletedCampaignsAdapter,
} from 'lib/adapters/streamer'
import { Api } from 'lib/api'
import {
  ICampaignDetailsPayload,
  ICampaignsPayload,
  ICompletedCampaignsCountPayload,
  ICompletedCampaignsPayload,
} from 'lib/types/payloads/streamer-campaigns/brand-awareness'
import { IResponse, IResponseData, IResponseMessage } from 'lib/types/response'

export const getCampaigns = () => {
  return Api
    .get<IResponse<ICampaignsPayload>>('streamer/campaigns')
    .then(res => fromCapaignsAdapter(res))
}

export const getCompletedCampaigns = (page: number) => {
  return Api
    .get<IResponse<ICompletedCampaignsPayload>>('streamer/campaigns/closed', { page })
    .then(res => fromCompletedCampaignsAdapter(res))
}

export const getCompletedCampaignsCount = () => {
  return Api
    .get<IResponse<ICompletedCampaignsCountPayload>>('streamer/campaigns/closed/count')
    .then(res => res)
}

export const getCampaignInfo = (slug: string) => {
  return Api
    .get<IResponseData<ICampaignDetailsPayload>>('streamer/campaigns/info', { slug })
    .then(res => fromCampaignDetailsAdapter(res.data))
}

export const changeCampaignStatus = (slug: string) => {
  return Api
    .post<IResponseMessage>('streamer/campaigns/status', { slug })
    .then(res => res)
}
