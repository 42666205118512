/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'level-2': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M6 20l10-7 10 7v4l-10-7-10 7v-4z" _fill="#13BF34"/><path pid="1" d="M6 14l10-7 10 7v4l-10-7-10 7v-4z" _fill="#13BF34"/>'
  }
})
