import { Collection } from 'vue-mc'

import Locale from '@/models/Locale'

class Languages extends Collection {
  defaults () {
    return {}
  }

  options () {
    return {
      model: Locale,
    }
  }

  routes () {
    return {
      fetch: `${process.env.VUE_APP_API_URL}dictionary/locales`,
    }
  }
}

export default Languages
