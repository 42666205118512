import ElMessage from 'element/ElMessage'
import getFirstErrorMessage from 'lib/api/getFirstErrorMessage'
import { Api } from 'lib/api/instance'
import { removeToken } from 'lib/helpers/authToken'
import { Role } from 'lib/types/role'
import { Mutations } from 'store/auth/mutations'
import { ActionContext, ActionTree } from 'vuex'

import { type State } from './state'
import { ActionTypes } from './types/action-types'
import { MutationTypes } from './types/mutation-types'
import { StreamerPayload } from './types/streamer-payload'

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
} & Omit<ActionContext<State, State>, 'commit'>

export interface Actions {
  [ActionTypes.FETCH_STREAMER]({ commit }: AugmentedActionContext): Promise<StreamerPayload['user'] | void>
  [ActionTypes.FETCH_PARTNER]({ commit, state }: AugmentedActionContext): Promise<State['partner'] | void>
  [ActionTypes.SET_LOCALE](store: AugmentedActionContext, data: { locale: string }): Promise<unknown>
}

export const actions: ActionTree<State, State> & Actions = {
  async [ActionTypes.FETCH_STREAMER] ({ commit }) {
    try {
      const data: StreamerPayload = await Api.get('streamer/profile') as StreamerPayload
      commit(MutationTypes.UPDATE_STREAMER, data)
      commit(MutationTypes.SET_DEBUG, data?.user?.debug_active)
      return data.user
    }
    catch {
      removeToken()
    }
  },

  async [ActionTypes.FETCH_PARTNER] ({ commit, state }) {
    try {
      const data = await state.partner.fetch()
      commit(MutationTypes.UPDATE_ROLE, Role.PARTNER)
      commit(MutationTypes.SET_DEBUG, data?.user?.debug_active)
      commit(MutationTypes.SET_EXTENDED_ROLE, data?.user?.role_extended)
      return state.partner
    }
    catch (err) {
      const message = getFirstErrorMessage(err as Error, 'Ошибка запроса пользователя: ')
      ElMessage.error(message)
      removeToken()
    }
  },

  async [ActionTypes.SET_LOCALE] (store, data) {
    return Api.post('locale/save', data)
  },
}
