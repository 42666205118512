/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'heart': {
    width: 18,
    height: 16,
    viewBox: '0 0 18 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8.093 1.78A4.375 4.375 0 005 .5h-.375A4.375 4.375 0 00.25 4.875v.375a4.375 4.375 0 001.282 3.094L9 15.813l7.468-7.469A4.376 4.376 0 0017.75 5.25v-.375A4.375 4.375 0 0013.375.5H13a4.375 4.375 0 00-3.094 1.282L9 2.687l-.907-.906z" _fill="#266FFE"/>'
  }
})
