/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'link-arrow': {
    width: 11,
    height: 9,
    viewBox: '0 0 11 9',
    data: '<path pid="0" d="M6.527 9l-.766-.756 3.152-3.152H.75V4h8.163L5.761.857 6.527.09l4.455 4.454L6.527 9z" _fill="#266FFE"/>'
  }
})
