/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'twitch-icon': {
    width: 14,
    height: 16,
    viewBox: '0 0 14 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M0 3l3-3h11v8l-5 5H7l-3 3v-3H0V3zm4-2h9v6.5L10.5 10H8l-2 2v-2H4V1zm4 2H7v3.5h1V3zm2 0h1v3.5h-1V3z" _fill="#fff"/>'
  }
})
