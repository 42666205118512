/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'copy': {
    width: 12,
    height: 14,
    viewBox: '0 0 12 14',
    data: '<g _fill="#000" fill-rule="nonzero"><path pid="0" d="M4 3h7a1 1 0 011 1v9a1 1 0 01-1 1H4a1 1 0 01-1-1V4a1 1 0 011-1zm0 1v9h7V4H4z"/><path pid="1" d="M8 0v1H1v10H0V1a1 1 0 011-1h7z"/></g>'
  }
})
