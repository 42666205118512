import { Collection } from 'vue-mc'

import Country from '@/models/Country'

class Countries extends Collection {
  defaults () {
    return {}
  }

  options () {
    return {
      model: Country,
    }
  }

  routes () {
    return {
      fetch: `${process.env.VUE_APP_API_URL}dictionary/countries`,
    }
  }
}

export default Countries
