import { Api } from 'lib/api'
import { ITwitchExtensionPayload } from 'lib/types/payloads/twitch-extension'
import { IResponse, IResponseMessage } from 'lib/types/response'
import { ITwitchExtensionData } from 'lib/types/twitch-extension'

export const get = () => {
  return Api
    .get<IResponse<ITwitchExtensionPayload>>('streamer/extension/twitch')
    .then(res => res)
}

export const update = (data: ITwitchExtensionData) => {
  return Api.post<IResponseMessage>('streamer/extension/twitch/save', data)
    .then(res => res)
}
