/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'table-filter-icon': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3 2h2v12H3V2zm4 0h2v12H7V2zm6 0h-2v12h2V2z" _fill="#666"/>'
  }
})
