<template>
  <div class="app-preloader">
    <UplifyLogo big />
  </div>
</template>

<script>
import UplifyLogo from 'components/UplifyLogo'

export default {
  components: {
    UplifyLogo,
  },
}
</script>

<style lang="scss" scoped>
.app-preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}
</style>
