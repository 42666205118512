/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chevron-right': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.354 12.354l-.708-.707L9.293 8 5.646 4.354l.708-.708L10.707 8l-4.353 4.354z"/>'
  }
})
