import Vue from 'vue'

import exchange from './exchange'
import floatToCurrency from './floatToCurrency'
import numberToRanks from './numberToRanks'

Vue.filter('toCurrency', floatToCurrency)
Vue.filter('toRanks', numberToRanks)
Vue.filter('exchange', exchange)

export {
  floatToCurrency,
  numberToRanks,
  exchange,
}
