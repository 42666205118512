import { Api } from 'lib/api'
import { IPartnerPayload } from 'lib/types/payloads/partner'
import { IUpdatePasswordPayload } from 'lib/types/payloads/update-password'
import { IResponseData, IResponseMessage } from 'lib/types/response'

export const get = () => {
  return Api
    .get<IResponseData<IPartnerPayload>>('partner/profile')
    .then(res => res)
}

export const update = (data: IPartnerPayload) => {
  return Api
    .post<IResponseMessage>('partner/profile', data)
    .then(res => res)
}

export const resendActivationEmail = () => {
  return Api
    .get<IResponseMessage>('partner/profile/email/resend')
    .then(res => res)
}

export const updatePassword = (data: IUpdatePasswordPayload) => {
  return Api
    .post<IResponseMessage>('partner/profile/password', data)
    .then(res => res)
}
