import { streamerFreemiumGoalModelToPayloadAdapter } from 'lib/adapters/streamer/streamerFreemiumAdapters'
import { Api } from 'lib/api'
import { IdType } from 'lib/types/base-types'
import { IResponseData, IResponseMessage } from 'lib/types/response'
import { IFreemiumGoal, IFreemiumGoalModel, IFreemiumPoll, IFreemiumPollModel, IFreemiumSetup } from 'lib/types/streamer-freemium/freemium-setup'
import { IFreemiumStats } from 'lib/types/streamer-freemium/freemium-supporters'

const remoweEmptyPollAnswers = (data: IFreemiumPollModel): IFreemiumPollModel => {
  return {
    ...data,
    answers: data.answers.filter(item => item.length),
  }
}

export const getSetup = async () => {
  return Api
    .get<IResponseData<IFreemiumSetup>>('streamer/freemium/setup')
    .then(res => res)
}

export const getStats = async () => {
  return Api
    .get<IResponseData<IFreemiumStats>>('streamer/freemium/stats')
    .then(res => res)
}

export const disableGoal = async (id: IdType) => {
  return Api
    .post<IResponseMessage>(`streamer/freemium/goal/${id}/disable`)
    .then(res => res)
}

export const disablePoll = async (id: IdType) => {
  return Api
    .post<IResponseMessage>(`streamer/freemium/poll/${id}/disable`)
    .then(res => res)
}

export const enableGoal = async (id: IdType) => {
  return Api
    .post<IResponseMessage>(`streamer/freemium/goal/${id}/enable`)
    .then(res => res)
}

export const enablePoll = async (id: IdType) => {
  return Api
    .post<IResponseMessage>(`streamer/freemium/poll/${id}/enable`)
    .then(res => res)
}

export const createGoal = async (data: IFreemiumGoalModel) => {
  const formattedData = streamerFreemiumGoalModelToPayloadAdapter(data)
  return Api
    .post <IResponseMessage & { goal: IFreemiumGoal }>('streamer/freemium/goal', formattedData)
    .then(res => res)
}

export const updateGoal = async (id: IdType, data: IFreemiumGoalModel) => {
  const formattedData = streamerFreemiumGoalModelToPayloadAdapter(data)
  return Api
    .post<IResponseMessage>(`streamer/freemium/goal/${id}`, formattedData)
    .then(res => res)
}

export const createPoll = async (data: IFreemiumPollModel) => {
  const formattedData = remoweEmptyPollAnswers(data)
  return Api
    .post<IResponseMessage & { poll: IFreemiumPoll }>('streamer/freemium/poll', formattedData)
    .then(res => res)
}

export const updatePoll = async (id: IdType, data: IFreemiumPollModel) => {
  const formattedData = remoweEmptyPollAnswers(data)
  return Api
    .post<IResponseMessage>(`streamer/freemium/poll/${id}`, formattedData)
    .then(res => res)
}
