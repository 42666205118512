/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'circle-question': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<path pid="0" d="M7 .333A6.67 6.67 0 00.333 7 6.67 6.67 0 007 13.667 6.67 6.67 0 0013.667 7 6.67 6.67 0 007 .333zm.667 11.334H6.333v-1.334h1.334v1.334zM9.047 6.5l-.6.613C7.967 7.6 7.667 8 7.667 9H6.333v-.333c0-.734.3-1.4.78-1.887l.827-.84c.247-.24.393-.573.393-.94 0-.733-.6-1.333-1.333-1.333S5.667 4.267 5.667 5H4.333a2.666 2.666 0 115.334 0c0 .587-.24 1.12-.62 1.5z" _fill="#666"/>'
  }
})
