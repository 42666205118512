/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'refresh-loop': {
    width: 10,
    height: 10,
    viewBox: '0 0 10 10',
    data: '<path pid="0" d="M5 1c-.99 0-1.9.361-2.598.958a.334.334 0 10.433.507A3.316 3.316 0 015 1.667a3.329 3.329 0 013.317 3h-.984l1.334 2 1.333-2H8.983A4.004 4.004 0 005 1zM1.333 3.333L0 5.333h1.017A4.004 4.004 0 005 9c.99 0 1.9-.361 2.598-.958a.334.334 0 10-.433-.507A3.316 3.316 0 015 8.333a3.329 3.329 0 01-3.317-3h.984l-1.334-2z" _fill="#3679FF"/>'
  }
})
