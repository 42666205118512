import Vue from 'vue'
import Vuex from 'vuex'

import auth from '@/store/auth'
import dictionaries from '@/store/dictionaries'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // @ts-ignore
    alert: null,
    // @ts-ignore
    twitchAlert: false,
    // @ts-ignore
    demo: false,
    isLayoutDebug: false,
  },
  mutations: {
    showAlert (state, payload) {
      // @ts-ignore
      state.alert = payload
    },
    hideAlert (state) {
      // @ts-ignore
      state.alert = null
    },
    showTwitchAlert (state) {
      // @ts-ignore
      state.twitchAlert = true
    },
    hideTwitchAlert (state) {
      // @ts-ignore
      state.twitchAlert = false
    },
    showDemo (state) {
      // @ts-ignore
      state.demo = true
    },
    hideDemo (state) {
      // @ts-ignore
      state.demo = false
    },
    setIsLayoutDebug (state, payload) {
      // @ts-ignore
      state.isLayoutDebug = payload
    },
  },
  actions: {
    showAlert ({ commit }, data) {
      commit('showAlert', data)
    },
    hideAlert ({ commit }) {
      commit('hideAlert')
    },
    showTwitchAlert ({ commit }) {
      commit('showTwitchAlert')
    },
    hideTwitchAlert ({ commit }) {
      commit('hideTwitchAlert')
    },
    showDemo ({ commit }) {
      commit('showDemo')
    },
    hideDemo ({ commit }) {
      commit('hideDemo')
    },
    setIsLayoutDebug ({ commit }, data) {
      commit('setIsLayoutDebug', data)
    },
  },
  getters: {
    // @ts-ignore
    alert: ({ alert }) => alert,
    // @ts-ignore
    twitchAlert: ({ twitchAlert }) => twitchAlert,
    // @ts-ignore
    demo: ({ demo }) => demo,
    // @ts-ignore
    isLayoutDebug: ({ isLayoutDebug }) => isLayoutDebug,
  },
  modules: {
    auth,
    dictionaries,
  },
})
