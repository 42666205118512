import { IStreamerPayload } from 'lib/types/payloads/streamer'
import { IStreamer } from 'lib/types/streamer'

export const fromPayloadAdapter = (payload: IStreamerPayload): IStreamer => {
  return {
    id: payload.user.user_id,
    name: payload.user.name,
    email: payload.user.email,
    locale: payload.user.locale,
    language: payload.user.language,
    country: payload.user.country,
    gender: payload.user.gender,
    balance: payload.user.balance,
    currency: payload.user.currency,
    birthday: payload.user.birthday,
    referral: payload.user.referral,
    emailVerified: payload.user.email_verified,
    signedUp: payload.user.signed_up,
    userHash: payload.user.user_hash,
    domain: payload.user.domain,
    tier: payload.tier,
    twitch: payload.twitch ? {
      ...payload.twitch,
      providerId: payload.twitch.provider_id,
    } : null,
    youtube: payload.youtube ? {
      ...payload.youtube,
      providerId: payload.youtube.provider_id,
    } : null,
    trovo: payload.trovo ? {
      ...payload.trovo,
      providerId: payload.trovo.provider_id,
    } : null,
    discord: payload.discord ? {
      ...payload.discord,
      providerId: payload.discord.provider_id,
    } : null,
  }
}
