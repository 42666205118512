/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'earth': {
    width: 15,
    height: 15,
    viewBox: '0 0 15 15',
    data: '<path pid="0" d="M7.5.5a7 7 0 110 14 7 7 0 010-14zm1.689 10.002H5.81a8.84 8.84 0 00.487 1.554c.226.527.482.93.737 1.182.191.19.344.262.465.262.12 0 .274-.073.465-.262.255-.252.511-.655.737-1.182.195-.455.36-.98.487-1.554zm-4.4 0L2.303 10.5a6.015 6.015 0 003.488 2.752 6.075 6.075 0 01-.412-.803 10.096 10.096 0 01-.59-1.949zm7.908-.001H10.21a10.097 10.097 0 01-.59 1.949 6.134 6.134 0 01-.412.803 6.017 6.017 0 003.488-2.752zm-8.073-5H1.841A5.99 5.99 0 001.5 7.5c0 .702.12 1.375.342 2h2.782a15.874 15.874 0 01-.124-2c0-.688.043-1.36.124-2zM9.367 5.5H5.633a14.805 14.805 0 00-.133 2c0 .693.046 1.367.133 2.001h3.734c.087-.634.133-1.308.133-2.001 0-.693-.046-1.366-.133-2zm3.792 0h-2.783c.081.64.124 1.312.124 2s-.043 1.36-.124 2h2.782a5.99 5.99 0 00.342-2 5.99 5.99 0 00-.341-2zM5.79 1.746l-.024.008A6.015 6.015 0 002.303 4.5h2.486c.146-.719.345-1.378.59-1.95.127-.296.265-.565.412-.804zM7.5 1.5l-.063.006c-.11.022-.243.098-.402.256-.255.252-.511.655-.737 1.182-.195.456-.36.98-.487 1.555h3.378a8.842 8.842 0 00-.487-1.555c-.226-.527-.482-.93-.737-1.182-.16-.158-.292-.234-.402-.256L7.5 1.5zm1.709.247l.084.14c.116.203.226.424.328.663.245.572.444 1.231.59 1.95h2.486a6.019 6.019 0 00-3.213-2.664l-.275-.09z" _fill="#666" fill-rule="nonzero"/>'
  }
})
