/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'star': {
    width: 14,
    height: 13,
    viewBox: '0 0 14 13',
    data: '<path pid="0" d="M7 10.513L11.12 13l-1.093-4.687 3.64-3.153-4.794-.407L7 .333l-1.873 4.42-4.794.407 3.64 3.153L2.88 13 7 10.513z" _fill="#666"/>'
  }
})
