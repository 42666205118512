import { Currency } from 'lib/types/currency'

import store from '@/store'

export default (
  sum: number | string,
  decimals = true,
  currency: Currency = store.getters['auth/currency'],
): string => {
  sum = typeof sum === 'string' ? parseFloat(sum) : sum

  let countryCode: string
  let currencyCode: string
  let currencyDisplay: 'symbol' | 'code' = 'symbol'

  switch (currency) {
    case Currency.EUR:
      countryCode = 'de-DE'
      currencyCode = 'EUR'
      break
    case Currency.RUB:
      countryCode = 'ru-RU'
      currencyCode = 'RUB'
      break
    case Currency.INR:
      countryCode = 'hi-IN'
      currencyCode = 'INR'
      break
    case Currency.BRL:
      countryCode = 'pt-BR'
      currencyCode = 'BRL'
      break
    case Currency.MXN:
      countryCode = 'es-MX'
      currencyCode = 'MXN'
      currencyDisplay = 'code'
      break
    default:
      countryCode = 'en-US'
      currencyCode = 'USD'
      break
  }

  const formatter = new Intl.NumberFormat(countryCode, {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay,
    maximumFractionDigits: decimals ? 2 : 0,
    minimumFractionDigits: decimals ? 2 : 0,
  })

  return formatter?.format(sum)
}
