/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'trovo-icon': {
    width: 16,
    height: 16,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M25.97 21.114c-.44-1.764-1.76-2.94-3.62-2.926-1.821.014-2.832 1.17-2.97 2.453-.098.946.261 1.705.785 2.016.963.571 1.64-.402 1.264-.763-.241-.232-.353-.429-.3-.78.052-.352.321-.704.955-.704.634 0 1.168.453 1.282 1.39a2.369 2.369 0 01-1.877 2.574c-1.626.343-3.266-.524-3.846-1.921-.58-1.397-.424-3.575.966-4.907 1.101-1.055 2.524-1.444 3.74-1.444l4.057.178a.633.633 0 00.643-.478l1.732-7.076a.649.649 0 00.019-.154c0-.35-.243-.633-.594-.633H20.79a.317.317 0 01-.308-.388l.829-3.576a.632.632 0 00-.617-.775h-7.677a.95.95 0 00-.917.702l-.849 3.572a.634.634 0 01-.611.468H5.724a.95.95 0 00-.926.743l-1.583 7.037a.657.657 0 00.033.382.634.634 0 00.585.39h5.043a.474.474 0 01.466.567c-.148.74-.249 1.488-.302 2.24-.153 2.193.423 4.596 2.154 6.472 2.946 3.191 8.385 3.376 11.603 1.603 2.586-1.427 3.724-4.053 3.173-6.262z"/>'
  }
})
