import { Role, type RoleType } from 'lib/types/role'
import { GetterTree } from 'vuex'

import { State } from './state'

export type Getters = {
  user(state: State): any
  name(state: State): string
  balance(state: State): number
  role(state: State): RoleType | undefined
  tier(state: State): string
  partner(state: State): any
  currency(state: State): string
  isFilled(state: State): boolean
  debugActive(state: State): boolean
}

// TODO: переделать работу с моделями партнера и стримера (https://grapheme2.atlassian.net/browse/UPLF-674)
export const getters: GetterTree<State, State> & Getters = {
  user: ({ user, partner }) => {
    if (user) return user
    if (partner.bootstrapped) return partner
    return null
  },
  name: ({
    twitch,
    user,
    partner,
    role,
  // @ts-ignore
  }) => role === Role.PARTNER ? partner?._.name : twitch?.nickname || user?.name,
  balance: ({
    user,
    partner,
    role,
  // @ts-ignore
  }) => role === Role.PARTNER ? partner?.balance : user?.balance,
  // @ts-ignore
  role: ({ role }) => role,
  tier: ({ tier }) => tier || '—',
  domain: ({
    user,
    partner,
    role,
  // @ts-ignore
  }) => role === Role.PARTNER ? partner?.domain : user?.domain,
  partner: ({ partner }) => partner,
  // @ts-ignore
  currency: ({ user, partner, role }) => role === Role.STREAMER ? user.currency : partner.currency,
  isFilled: ({ user }) => {
    // @ts-ignore
    if (!user || !user.$) return false
    // @ts-ignore
    const { birthday, country, email, gender, language } = user.$
    return Boolean(birthday && country && email && gender && language)
  },
  debugActive: ({ debugActive }) => debugActive,
}
