import VueMarkdownEditor from '@kangc/v-md-editor'
import editorUS from '@kangc/v-md-editor/lib/lang/en-US'
import editorEs from '@kangc/v-md-editor/lib/lang/es-ES'
import editorRu from '@kangc/v-md-editor/lib/lang/ru-RU'
import elementLocaleEn from 'element-ui/lib/locale/lang/en'
import elementLocaleEs from 'element-ui/lib/locale/lang/es'
import elementLocalePt from 'element-ui/lib/locale/lang/pt-br'
import elementLocaleRu from 'element-ui/lib/locale/lang/ru-RU'
import elementLocale from 'element-ui/src/locale'

export default {
  methods: {
    $setElementUILocale (locale) {
      switch (locale) {
        case 'ru':
          elementLocale.use(elementLocaleRu)
          VueMarkdownEditor.lang.use('ru-RU', editorRu)
          break
        case 'pt':
          elementLocale.use(elementLocalePt)
          VueMarkdownEditor.lang.use('es-ES', editorEs)
          break
        case 'es':
          elementLocale.use(elementLocaleEs)
          VueMarkdownEditor.lang.use('es-ES', editorEs)
          break
        default:
          elementLocale.use(elementLocaleEn)
          VueMarkdownEditor.lang.use('en-US', editorUS)
      }
    },
  },
}
