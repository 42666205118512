export default {
  methods: {
    $locale (str, ...args) {
      // set in component data
      let localePath = this.localePath

      if (!localePath) {
        const role = this.$store.getters['auth/role'] || 'auth'
        const page = this.$route.meta.locale || this.$route.name
        localePath = `views.${role}.${page}`
      }

      return this.$t(`${localePath}.${str}`, ...args)
    },
    $rootLocale (str, ...args) {
      return this.$t(str, ...args)
    },
  },
}
