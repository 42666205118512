/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dots': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<circle pid="0" cx="8" cy="8" r="1" _fill="#1A1A1A"/><circle pid="1" cx="4" cy="8" r="1" _fill="#1A1A1A"/><circle pid="2" cx="12" cy="8" r="1" _fill="#1A1A1A"/>'
  }
})
