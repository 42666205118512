/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'twitter-icon': {
    width: 16,
    height: 13,
    viewBox: '0 0 16 13',
    data: '<path pid="0" d="M5.032 13.002c6.037 0 9.34-5.003 9.34-9.341 0-.142 0-.284-.01-.425A6.68 6.68 0 0016 1.538c-.6.265-1.235.439-1.886.515A3.294 3.294 0 0015.558.24a6.579 6.579 0 01-2.085.797A3.286 3.286 0 007.88 4.03 9.32 9.32 0 011.114.598 3.285 3.285 0 002.13 4.982a3.259 3.259 0 01-1.49-.411v.042A3.283 3.283 0 003.272 7.83c-.483.132-.99.152-1.482.058a3.286 3.286 0 003.067 2.278A6.587 6.587 0 010 11.527 9.293 9.293 0 005.032 13" _fill="#3679FF" fill-rule="evenodd"/>'
  }
})
