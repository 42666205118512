/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'x': {
    width: 9,
    height: 9,
    viewBox: '0 0 9 9',
    data: '<path pid="0" d="M5.231 4.5L9 8.27 8.27 9 4.5 5.23.73 9 0 8.27 3.769 4.5 0 .73.73 0 4.5 3.77 8.27 0 9 .73z" _fill="#666" fill-rule="nonzero" opacity=".9"/>'
  }
})
