/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'close-menu-icon': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#1A1A1A" d="M5.636 4.222l14.142 14.142-1.414 1.414L4.222 5.636z"/><path pid="1" _fill="#1A1A1A" d="M4.222 18.364L18.364 4.222l1.414 1.414L5.636 19.778z"/>'
  }
})
