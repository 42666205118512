/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'error-icon': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M8 1C4.136 1 1 4.136 1 8s3.136 7 7 7 7-3.136 7-7-3.136-7-7-7zm.7 10.5H7.3v-1.4h1.4v1.4zm0-2.8H7.3V4.5h1.4v4.2z" _fill="#fff"/>'
  }
})
