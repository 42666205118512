/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vk-icon': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.123 1.123C0 2.246 0 4.053 0 7.667v.666c0 3.614 0 5.421 1.123 6.544C2.246 16 4.053 16 7.667 16h.667c3.614 0 5.42 0 6.543-1.123C16 13.754 16 11.947 16 8.333v-.666c0-3.614 0-5.421-1.123-6.544C13.755 0 11.947 0 8.334 0h-.667C4.053 0 2.246 0 1.123 1.123zM3.167 5h1.167c.298 0 .41.13.522.454.571 1.662 1.533 3.114 1.929 3.114.148 0 .216-.068.216-.445V6.405c-.026-.489-.197-.7-.324-.857-.078-.097-.14-.172-.14-.28 0-.13.112-.268.298-.268h1.833c.248 0 .334.132.334.429V7.74c0 .247.107.334.181.334.149 0 .272-.087.544-.359.841-.939 1.434-2.385 1.434-2.385A.504.504 0 0111.67 5h1.167c.353 0 .427.182.353.429-.148.68-1.57 2.682-1.57 2.682-.124.198-.173.297 0 .52.06.084.19.212.34.357.153.15.326.32.463.483.502.563.88 1.039.985 1.367.096.329-.07.495-.404.495h-1.167c-.313 0-.47-.175-.81-.553-.144-.16-.321-.357-.557-.593-.692-.667-.989-.754-1.162-.754-.235 0-.305.067-.305.4v1.047c0 .287-.092.453-.834.453-1.236 0-2.595-.75-3.559-2.134-1.446-2.027-1.842-3.56-1.842-3.87 0-.172.067-.329.4-.329z" _fill="#666"/>'
  }
})
