import Client from 'lib/api/client'

import { Api } from './instance'

export class SspClient extends Client {}

export const client = new SspClient()

export class SspMediaApi extends Api {
  static client = client
  static apiUrl = process.env.VUE_APP_SSP_MEDIA_API_URL
}
