import { payloadToWidgetSettingsAdapter, widgetSettingToPayloadAdapter } from 'lib/adapters/widgetPayloadAdapter'
import { Api } from 'lib/api'
import { IWidgetSettingsPayload } from 'lib/types/payloads/widget'
import { IResponse, IResponseMessage } from 'lib/types/response'

export const get = () => {
  return Api
    .get<IResponse<IWidgetSettingsPayload>>('/streamer/widget')
    .then(res => payloadToWidgetSettingsAdapter(res))
}

export const update = (data: IWidgetSettingsPayload) => {
  return Api
    .post<IResponseMessage>('streamer/widget', widgetSettingToPayloadAdapter(data))
    .then(res => res)
}

export const refreshDock = () => {
  return Api
    .post<IResponseMessage>('streamer/widget/obs/refresh')
    .then(res => res)
}
