<template>
  <div id="app">
    <div
      id="ads-block"
      class="ads-block"
    />
    <MainAlert />
    <TwitchAlert />
    <DemoInfo />
    <AppComponent v-if="loaded" />
    <AppPreloader v-else />
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import { Analytic } from 'lib/helpers/Analytic'
import { AUTH_IS_DEMO, getRole, getToken, setFreemiumToken, setToken } from 'lib/helpers/authToken'

import DemoInfo from 'components/DemoInfo'
import MainAlert from 'components/MainAlert'
import TwitchAlert from 'components/TwitchAlert'
import AppComponent from '@/AppComponent'
import AppPreloader from '@/AppPreloader'

export default {
  components: {
    AppPreloader,
    AppComponent,
    MainAlert,
    TwitchAlert,
    DemoInfo,
  },

  data: () => ({
    loaded: false,
  }),

  async created () {
    const skipPreload = /widget-page|panel-page|creative-preview|streamer-email-verification/.test(this.$route.name)
    if (skipPreload) {
      this.loaded = true
      return
    }

    this.checkAccessToken()

    const user = await this.getUser()

    setFreemiumToken(user?.user_id)
    this.pushDataLayer(user)
    this.pushToHeap(user)
    // this.configureRollbar(user)
    await this.fetchDictionaries()

    this.loaded = true
  },

  mounted () {
    this.setFavicon()
    this.checkAdBlock()
    this.checkIsDemo()
    this.checkIsLayoutDebug()
  },

  methods: {
    ...mapActions({
      fetchStreamer: 'auth/FETCH_STREAMER',
      fetchPartner: 'auth/FETCH_PARTNER',
      fetchDictionaries: 'dictionaries/fetchDictionaries',
    }),

    async getUser () {
      if (!getToken()) return

      let user
      if (getRole() === 'streamer') {
        user = await this.fetchStreamer()
      }
      if (getRole() === 'partner') {
        user = await this.fetchPartner()
      }

      return user
    },

    // only for streamer role
    checkAccessToken () {
      if (this.$route.query.access_token) {
        setToken(this.$route.query.access_token, 'streamer')

        // remove access token from url
        this.$router.replace({
          ...this.$router.currentRoute,
          query: {
            access_token: undefined,
          },
        })
      }
    },

    async checkAdBlock () {
      // setTImeout needs to all redirects will done
      setTimeout(() => {
        const path = window.location.pathname
        const bait = document.getElementById('ads-block')
        const isDisplayNone = getComputedStyle(bait).display === 'none'

        if (/auth/.test(path)) return

        if (bait && isDisplayNone) {
          this.$store.dispatch('showAlert', {
            text: `<b>${this.$t('other.adblock.bold')}</b> ${this.$t('other.adblock.normal')}`,
            type: 'error',
          })
        }
      }, 1000)
    },

    async checkIsDemo () {
      setTimeout(() => {
        if (localStorage.getItem(AUTH_IS_DEMO)) {
          this.$store.dispatch('showDemo')
        }
      }, 1000)
    },

    checkIsLayoutDebug () {
      const isDebug = this.$route.query?.layout_debug === '1'
      this.$store.dispatch('setIsLayoutDebug', isDebug)
    },

    pushDataLayer (user) {
      if (process.env.NODE_ENV !== 'production' || !user) return
      window.dataLayer.push({
        'intercom-name': user.name || '',
        'intercom-email': user.email || '',
        'intercom-signed_up': user.signed_up || '',
        'intercom-avatar': user.twitch?.avatar || '',
        'intercom-country': user.country || '',
        'intercom-lang': user.language || '',
        'intercom-nickname': user.twitch?.displayname || user.name || '',
        'intercom-plan': '',
        'intercom-role': getRole() || '',
        'intercom-user_id': user.user_id || '',
        'intercom-twitch_id': user.twitch?.provider_id || '',
        'intercom-user_hash': user.user_hash || '',
      })
    },

    pushToHeap (user) {
      if (getRole() !== 'streamer') {
        return
      }

      if (user.user_id) {
        Analytic.heapIdentity(user.user_id)
        Analytic.heapAddUserProperties({ Nickname: user.twitch?.displayname || user.name || '', Email: user?.email })
      }
    },

    setFavicon () {
      const baseUrl = process.env.BASE_URL
      let favBaseUrl = baseUrl

      if (this.$userDomain === 'streamo') {
        favBaseUrl = `${baseUrl}fav_streamo/`
      }

      const defaultFav = `
        <link rel="icon" href="${favBaseUrl}favicon.ico">
        <link rel="apple-touch-icon" sizes="180x180" href="${favBaseUrl}apple-touch-icon.png">
        <link rel="icon" type="image/png" sizes="512x512" href="${favBaseUrl}favicon-512x512.png">
        <link rel="icon" type="image/png" sizes="192x192" href="${favBaseUrl}favicon-192x192.png">
        <link rel="icon" type="image/png" sizes="32x32" href="${favBaseUrl}favicon-32x32.png">
        <link rel="icon" type="image/png" sizes="16x16" href="${favBaseUrl}favicon-16x16.png">
        <link rel="manifest" href="${favBaseUrl}site.webmanifest">
        <link rel="mask-icon" href="${favBaseUrl}safari-pinned-tab.svg" color="#266ffe">`

      document.head.insertAdjacentHTML('beforeend', defaultFav)
    },

    // configureRollbar (user) {
    //   vm.$rollbar.configure({
    //     payload: {
    //       user,
    //     },
    //   })
    // },
  },
}
</script>

<style lang="scss">
@import '~assets/css/fonts.scss';
@import '~assets/css/normalize.scss';
@import '~assets/css/_font-styles.scss';
@import '~assets/css/_style-helpers.scss';
@import '~assets/css/_element-ui-override.scss';
@import '~assets/css/element-ui-themes/index.scss';
</style>
