/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wallet': {
    width: 16,
    height: 15,
    viewBox: '0 0 16 15',
    data: '<path pid="0" d="M2 0a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-.352c-.11.013-.22.019-.333.019h-6a3 3 0 01-3-3V5.333a3 3 0 013-3h6c.112 0 .224.007.333.019V2a2 2 0 00-2-2H2zm6.667 4.333a1 1 0 00-1 1v4.334a1 1 0 001 1h6a1 1 0 001-1V5.333a1 1 0 00-1-1h-6zM12.083 7.5a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z" _fill="#FFF" fill-rule="evenodd"/>'
  }
})
