/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14 7A7 7 0 110 7a7 7 0 0114 0zm-2.508 3.977C10.98 9.49 8.99 8.5 7 8.5c-1.99 0-3.98.99-4.492 2.477A5.985 5.985 0 007 13a5.985 5.985 0 004.492-2.023zM7 7a2 2 0 100-4 2 2 0 000 4z" _fill="#1A1A1A"/>'
  }
})
