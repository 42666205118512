/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pie-chart': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M13 6.5v1A6.5 6.5 0 116.5 1h1v5.5H13zm-1.092 2A5.499 5.499 0 116.5 2v5.5H12a5.5 5.5 0 01-.092 1z" _fill="#666"/><path pid="1" d="M8.5 0A5.5 5.5 0 0114 5.5H8.5V0z" _fill="#666"/>'
  }
})
