/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'edit': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M7 3H4a1 1 0 00-1 1v8a1 1 0 001 1h8a1 1 0 001-1V9h1v3a2 2 0 01-2 2H4a2 2 0 01-2-2V4a2 2 0 012-2h3v1z" clip-rule="evenodd"/><path pid="1" _fill="#666" fill-rule="evenodd" d="M13.586 3.828l-1.414-1.414-5.443 5.443-.354 1.768 1.768-.354 5.443-5.443zm-4.95 6.364l5.657-5.657a1 1 0 000-1.414l-1.414-1.414a1 1 0 00-1.415 0L5.808 7.364 5.1 10.899l3.535-.707z" clip-rule="evenodd"/>'
  }
})
