/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'extension': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M1 0a1 1 0 00-1 1v5a1 1 0 001 1h5a1 1 0 001-1V1a1 1 0 00-1-1H1zM1 9a1 1 0 00-1 1v5a1 1 0 001 1h5a1 1 0 001-1v-5a1 1 0 00-1-1H1zM9 1a1 1 0 011-1h5a1 1 0 011 1v5a1 1 0 01-1 1h-5a1 1 0 01-1-1V1zM11.5 13.5V15a1 1 0 102 0v-1.5H15a1 1 0 100-2h-1.5V10a1 1 0 10-2 0v1.5H10a1 1 0 100 2h1.5z" _fill="#266FFE"/>'
  }
})
